// export const BaseIP = 'http://192.168.29.112:9081/';

export const BaseIP = 'https://jainboardingkolhapur.com/';

export const adminLogin= BaseIP+ "api/admin/adminlogin" // Admin login (POST)

// ========Dashboard API's========================
export const adminDashboard= BaseIP+ "api/admin/DashBoard" // Admin Dashboard (GET)
export const adminDashboardEducation=BaseIP+"api/admin/educationCategory"// Admin Dashboard Education Category (GET)

// ========Member Profiles API's========================
export const adminMemberList= BaseIP+ "api/registration/select/list" // Admin member profile list (GET)
// ########==>> Edit Profile <<==########
export const adminUpdateProfile=BaseIP+'api/member/update/adminMemberProfile'  //Admin update profile (for edit page)
export const RegistrationSelect=BaseIP+"api/registration/select"//Personal detail api (for edit page)

// ========Photo Approval API's========================
export const adminPhotoApproveList=BaseIP+"api/admin/photoApprovalList"// Admin Photo Approve list (GET)
export const adminPhotoApproval=BaseIP+'api/admin/photoStatusSaveApproval'   //Admin photo status approval (PUT)

// ========Payment Approval API's========================
export const paymentApproveFindall=BaseIP+'api/payment/findall' //Admin Payment Approve Findall (GET)
export const paymentApproveModalData=BaseIP+'api/registration/select' //Admin Payment Approve Modal Data show (GET) (params = member_id)
export const paymentApproveStatusChange=BaseIP+'api/payment/paymentApproval' //Admin Payment Approve Status Change (PUT) 

// ========Inactive Members API's========================
export const adminInactiveMemberList=BaseIP+'api/admin/inActiveMemberList'  //Admin inactive member list (GET)

// ========Payment Pending Member API's========================
export const adminPaymentPendingList=BaseIP+'api/admin/paymentPendingList'  //Admin Payment pending list (GET)

// ========Payment Success Member API's========================
export const adminPaymentSuccessList=BaseIP+'api/admin/paymentSuccessList'  //Admin Payment success list (GET)



export const adminPaymentReportList=BaseIP+'api/admin/paymentSuccessList/Report'  //Admin payment report list

// ========ADMIN REPORT API's=========================
export const reportDataPDF=BaseIP+'api/registration/reportData' //Admin report list get API (GET) (params = melava/gender/category/srno)


export const adminInactiveDestroy=BaseIP+'api/admin/listDestroy' //Admin Report API


// ==================Admin BarCode=========
export const QrAdminLogin=BaseIP+"api/attendance/adminlogin"
export const EntryDetail=BaseIP+"api/attendance/list"

export const BarCodeRecipt=BaseIP+"api/attendance/barcode"
export const BarCodeScanningPerson=BaseIP+"api/attendance/attendanceDetails"
export const Getattendancedata=BaseIP+"api/attendance/getRegId"
export const selectregdataforsacn=BaseIP+"api/registration/select/scan/"
export const BarcodeScanPage=BaseIP+"api/registration/select/list"

// ============MEMBER ENTRY FORM API's======================
export const adminRegsitrationFirstPageSave=BaseIP+"api/member/adminMemberCreate"  //======new member creation post api (First Page) (POST)
export const adminRegsitrationSecondPageSave=BaseIP+"api/registration/update"  //======new member creation post api (Second Page) (Checkbox-expectations) (PUT)
export const adminPaymentAdd=BaseIP+"api/member/admin-paymentadd"//payment page add api (POST)



export const currentYearData=BaseIP+"api/admin/get-websitmessage"//current year data api (GET)

export const memberImagePath=BaseIP+"nodeApp/image"// member img path
export const adharImagePath=BaseIP+"nodeApp/adharcard_image"// adhar img path
export const taxImagePath=BaseIP+"nodeApp/paymentImage"// tax img path


