import React, { useState,useEffect } from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import '../AdminComponents/AdminLogin.css';

const ForgotPassword = () => {

    const navigate=useNavigate();
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [isValid, setIsValid] = useState(true);
   

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setIsValid(true);
  };

  const handleBirthDate = (e) => {
    setBirthDate(e.target.value);
    setIsValid(true);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault()
    if (email === "" || birthDate === "") {
        setIsValid(false); 
        return;
      }

    axios
      .post("https://reqres.in/api/forgot-password", {
        email: email,
        birthDate: birthDate,
      })
      .then(result => {
        alert('New password is successfully send on your registered email.')
      })
      .catch(error => {
        alert('Invalid username or birth date!')
      });

      navigate("/admin");
  };
      

  //------------------------------------------------css---------------------------

  const box = {
    height: '100vh',
    margin: '80px 20px 10px 20px',
    textAlign: 'center',
    display:"flex",
    justifyContent:"center",
    width:"100%",
  }
  const input = { padding: '10px', marginBottom: '20px', borderRadius:"50px", border:"1px solid" }
  const loginform = {
    border: 'none',
    padding: '20px',
    boxShadow: '5px 10px 15px 0px',
    backgroundColor: 'white',
    height:"350px",
    borderRadius:"50px",
  }
  const button = {
    margin: '20px 0px',
    padding: '10px',
    color: 'black',
    border:"1px solid",
    backgroundColor:"#979A9A",
    borderRadius:"50px",
  }
  const title = { textAlign: 'center', paddingBottom: '20px',paddingTop:"20px" }

  
  return (
    <>
      <div>
      <Row
        style={{
          display: 'flex',
          margin: '0px',
          justifyContent: 'center',
          backgroundColor: '#7f2623',
          backgroundImage:
            ' linear-gradient( 178.2deg,  rgba(118,8,23,1) 10.9%, rgba(158,12,33,1) 62.6% )',
        }}>
        <Col style={box} className='row'>
        <div className='col-12 col-md-4 col-sm-3'> </div>
          <div style={loginform} className='col-12 col-md-4 col-sm-6'>
            <h4 style={title}>FORGOT PASSWORD??</h4>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <input
                  className='col-8'
                  style={input}
                  type="email"
                  placeholder="Username" value={email}
                  onChange={handleEmail}
                />
              </Form.Group>

              <Form.Group controlId="formBirthDate">
               <div>
               <input
                  className="col-8"
                  style={input}
                  type="date"
                  placeholder="Date of birth" value={birthDate}
                   onChange={handleBirthDate}
                />
               </div>
              </Form.Group>
              
              {isValid ? null : (
                <div style={{ color: 'red', margin: '0px 10px',marginBottom:"-15px" }}>
                  All fields are compulsory*
                </div>
              )}
                <button style={button} onClick={handleForgotPassword} className='col-6'>
                    Send Password
                </button>

                <FormGroup>
                <Link
                  to="/admin"
                  style={{color: 'black',}}
                >
                  Go to Login
                </Link>
              </FormGroup>
            </Form>
          </div>
          <div className='col-12 col-md-4 col-sm-3'> </div>
        </Col>
      </Row>
    </div>
    </>
    
  )
}

export default ForgotPassword