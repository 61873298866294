import React, { useState, useEffect } from 'react'
import './EditProfileCSS.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Row, Col, } from 'react-bootstrap'
import men1 from "../../../assets/men1.jfif";
import logo from "../../../assets/Mainlogo.jpg";
import { Form, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminMasterHeader from '../../../CommonComponenets/AdminMasterHeader';
import { EducationMaster, SubeducationMaster, subcastMaster, IncomeMaster, RegsitrationSave, StateMaster, DistrictMaster, TalukaMaster, RegistrationSelect, } from '../../../AllApi/UserApi/UserApi';
import { adharImagePath, adminUpdateProfile, memberImagePath } from '../../../AllApi/AdminApi/AdminApi';
import { toast } from 'react-toastify';
import { Input, Select } from 'antd';

import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { style } from '@mui/system';
import AdminMasterFooter from '../../../CommonComponenets/AdminMasterFooter';


const { Option } = Select;

//==============================================================================================

const EditMainPage = () => {

     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const navigate = useNavigate()
  const location = useLocation();
  const select_member = location.state?.member_id;

  //----------Registration Form basic information data save------------//

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [Enfname, setEnfname] = useState("")
  const [Enmname, setEnmname] = useState("")
  const [Enlname, setEnlname] = useState("")
  const [Mrfname, setMrfname] = useState("")
  const [Mrmname, setMrmname] = useState("")
  const [Mrlname, setMrlname] = useState("")
  const [Gender, setGender] = useState("")
  const [MaritialStatus, setMaritialStatus] = useState("")
  const [Complextion, setComplextion] = useState("")
  const [Disability, setDisability] = useState("")
  const [Dateob, setDateob] = useState("")
  const [BirthTime, setBirthTime] = useState("")
  const [Height, setHeight] = useState("")
  const [SubCaste, setSubCaste] = useState("")

  const [Education, setEducation] = useState('')
  const [EduCategory, setEduCategory] = useState([])
  const [SubeducationData, setSubeducationData] = useState([]);
  const [EduType, setEduType] = useState("")
  const [OccupationDetail, setOccupationDetail] = useState("")
  const [AnnualIncome, setAnnualIncome] = useState("")

  const [FaFNameEn, setFaFNameEn] = useState("")
  const [FaMNameEn, setFaMNameEn] = useState("")
  const [FaLNameEn, setFaLNameEn] = useState("")
  const [FaFNameMr, setFaFNameMr] = useState("")
  const [FaMNameMr, setFaMNameMr] = useState("")
  const [FaLNameMr, setFaLNameMr] = useState("")
  const [FarmAcar, setFarmAcar] = useState("")
  const [FarmGuntha, setFarmGuntha] = useState("")
  const [OtherProperty, setOtherProperty] = useState("")
  const [MarriedBro, setMarriedBro] = useState("")
  const [UnMarriedBro, setUnMarriedBro] = useState("")
  const [MarriedSis, setMarriedSis] = useState("")
  const [UnMarriedSis, setUnMarriedSis] = useState("")
  // const [submitted, setSubmitted] = useState(false);
  const [Compatible, setCompatible] = useState(0);
  const [Businessman, setBusinessman] = useState(0);
  const [Serviceman, setServiceman] = useState(0);
  const [Farmer, setFarmer] = useState(0);
  const [HouseWife, setHouseWife] = useState(0);

  const [AddressEn, setAddressEn] = useState("")
  const [CityEn, setCityEn] = useState("")
  const [AddressMr, setAddressMr] = useState("")
  const [CityMr, setCityMr] = useState("")
  const [State, setState] = useState("")
  const [District, setDistrict] = useState("")
  const [Taluka, setTaluka] = useState("")
  const [Email, setEmail] = useState("")
  const [Mobno1, setMobno1] = useState("")
  const [Mobno2, setMobno2] = useState("")

  const [Member_id,setMember_id]=useState("")
  const [Regid,setRegid]=useState("")
  const [Image,setImage]=useState(null)
  const [adharImage,setAdharImage]=useState(null)



  // const [Registrationid, setRegistrationid] = useState();
  // const reg_id = JSON.parse(sessionStorage.getItem("Userid"));
  // const userdata = JSON.parse(sessionStorage.getItem("userdata"));

  // const Mobno = userdata.mob_no;
  // const FNameEn = userdata.fname;
  // const MNameEn = userdata.mname;
  // const LNameEn = userdata.lname;
  // const dofb = userdata.dateofbirth;
  // const FaFNameEn1 = userdata.mname
  // const FaLNameEn1 = userdata.lname
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); 
    }
  };
  const handleFileChange1 = (e) => {
    const file1 = e.target.files[0];
    if (file1) {
      setAdharImage(file1); 
    }
  };

  //----------------------updateData--------------------------------

  const updatedata = (event) => {

    event.preventDefault()
    const idArray = EduCategory.map((item) => item.id);
    const newEduCategory = idArray.join(", ");

if (!Image || !adharImage) {
  alert("Please select an image");
  return;
}
      
  const formData = new FormData();
  formData.append("file", Image);
  formData.append("file1", adharImage);

  formData.append("reg_id", Regid);
  formData.append("gender", Gender);
  formData.append("marital_status", MaritialStatus);
  formData.append("disability", Disability);
  formData.append("complextion", Complextion);
  formData.append("en_fname", Enfname);
  formData.append("en_mname", Enmname);
  formData.append("en_lname", Enlname);
  formData.append("mr_fname", Mrfname);
  formData.append("mr_mname", Mrmname);
  formData.append("mr_lname", Mrlname);
  formData.append("dob", Dateob);
  formData.append("birth_time", BirthTime);
  formData.append("height", Height);
  formData.append("subcaste_id",SubCaste);
  formData.append("edu_id", Education);
  formData.append("subedu_id", newEduCategory);
  formData.append("occupation", EduType);
  formData.append("occupation_detail", OccupationDetail);
  formData.append("income_id", AnnualIncome);
  formData.append("en_address", AddressEn);
  formData.append("mr_address", AddressMr);
  formData.append("en_village", CityEn);
  formData.append("mr_village", CityMr);
  formData.append("taluka_id", Taluka);
  formData.append("district_id", District);
  formData.append("state_id", State);
  formData.append("emailid", Email);
  formData.append("mobileno", Mobno1);
  formData.append("mobileno2", Mobno2);
  formData.append("en_fatherfname", FaFNameEn);
  formData.append("en_fathermname", FaMNameEn);
  formData.append("en_fatherlname", FaLNameEn);
  formData.append("mr_fatherfname", FaFNameMr);
  formData.append("mr_fathermname", FaMNameMr);
  formData.append("mr_fatherlname", FaLNameMr);
  formData.append("farm_ac", FarmAcar);
  formData.append("farm_g", FarmGuntha);
  formData.append("other_property", OtherProperty);
  formData.append("b_married", MarriedBro);
  formData.append("b_unmarried", UnMarriedBro);
  formData.append("s_married", MarriedSis);
  formData.append("s_unmarried", UnMarriedSis);
  formData.append("exp_compatible", Compatible);
  formData.append("exp_businessman", Businessman);
  formData.append("exp_serviceman", Serviceman);
  formData.append("exp_farmer", Farmer);
  formData.append("exp_housewife", HouseWife);
  formData.append("member_id", Member_id);
  

    axios
    .put(adminUpdateProfile, formData)
    .then((res) => {
      
      if (res.data.message === "UPDATE") {
        toast.success('Profile UPDATED successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        navigate('/admin/member/mp')

      } 
    })
    .catch((err) => {
      toast.error('Failed To Update, try again!!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    });

  }
  const [update, setUpdate] = useState()

  const convertToHHmm = (timeStr) => {
    const date = new Date(`2000-01-01T${timeStr}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const getdata = (SubeducationData) => {
    axios
      .get(`${RegistrationSelect}${select_member}`)
      .then((res) => {
        

        if (res.data.json !== null) {
          const api = res.data;
          const getSubeducationNameById = (id) => {
       
            const matchingOption = SubeducationData.find((option) => option.id === id);
            
            return matchingOption && matchingOption.subeducation_name ? matchingOption.subeducation_name : "Not Found";

         };

          api.map((api, index) => {
            setRegid(api.reg_id)
            setImage(api.image); // Add this line in the mapping loop
            setAdharImage(api.adharcard_image); // Add this line in the mapping loop
            setGender(api.gender?.toString())
            setMaritialStatus(api.marital_status?.toString())
            setDisability(api.disability)
            setComplextion(api.complextion)
            setEnfname(api.en_fname)
            setEnmname(api.en_mname)
            setEnlname(api.en_lname)
            setMrfname(api.mr_fname)
            setMrmname(api.mr_mname)
            setMrlname(api.mr_lname)
            
            // setDateob(api.dob)
            const [day, month, year] = api.dob.split('/');
            const convertedDate = `${year}-${month}-${day}`;
            setDateob(convertedDate)
            
            // setBirthTime(api.birth_time)
            const timeParts = api.birth_time.split(" ");
            if (timeParts.length === 2) {
              const [time, ampm] = timeParts;
              const [hoursStr, minutesStr] = time.split(":");
              let hours = parseInt(hoursStr, 10);
              if (ampm === "PM" && hours !== 12) {
                hours += 12;
              } else if (ampm === "AM" && hours === 12) {
                hours = 0;
              }

              const formattedTime = `${hours
                .toString()
                .padStart(2, "0")}:${minutesStr}`;
              
              setBirthTime(formattedTime);
            }

            setHeight(api.height)
            setSubCaste(api.subcaste_id)
            setEducation(api.edu_id);
            const subidarray = api.subedu_id.match(/[^,]+/g);
            const subid = subidarray.map((value) => (
             
              {
             
              id: parseInt(value),
              subeducation_name: getSubeducationNameById(parseInt(value)),
            })
            );

            setEduCategory(subid);
            setEduType(api.occupation)
            setOccupationDetail(api.occupation_detail)
            setAnnualIncome(api.income_id)
            setAddressEn(api.en_address)
            setAddressMr(api.mr_address)
            setCityEn(api.en_village)
            setCityMr(api.mr_village)
            setTaluka(api.taluka_id)
            setDistrict(api.district_id)
            setState(api.state_id)
            setEmail(api.email_id)
            setMobno1(api.mobileno)
            setMobno2(api.mobileno2)
            setFaFNameEn(api.en_fatherfname)
            setFaMNameEn(api.en_fathermname)
            setFaLNameEn(api.en_fatherlname)
            setFaFNameMr(api.mr_fatherfname)
            setFaMNameMr(api.mr_fathermname)
            setFaLNameMr(api.mr_fatherlname)
            setFarmAcar(api.farm_ac)
            setFarmGuntha(api.farm_g)
            setOtherProperty(api.other_property)
            setMarriedBro(api.b_married)
            setUnMarriedBro(api.b_unmarried)
            setMarriedSis(api.s_married)
            setUnMarriedSis(api.s_unmarried)
            setMember_id(api.member_id)


            setHouseWife(api.exp_houseWife);
            setCompatible(api.exp_compatible);
            setBusinessman(api.exp_businessman);
            setServiceman(api.exp_serviceman);
            setFarmer(api.exp_farmer);
            let expdata=[]
            
            if(api.exp_houseWife == 1){
              expdata.push("HouseWife")
            }
            if(api.exp_compatible == 1){
              expdata.push("Compatible")
            }
            if(api.exp_businessman == 1){
              expdata.push("Businessman")
            }
            if(api.exp_serviceman == 1){
              expdata.push("Serviceman")
            }
            if(api.exp_farmer == 1){
              expdata.push("Farmer")
            }

            if(expdata.length >0){
              setSelectedOptions(expdata)
            }
            
          })

          setUpdate(true)
        }
      })
      .catch((error) => {
      });
  };


  useEffect(() => {
    getdata()

  }, [])


  useEffect(() => {
      getdata(SubeducationData);
  }, [SubeducationData]);

  //master use state and other code
  const [EducationData, setEducationData] = useState([])
  const [SubCastData, setSubCastData] = useState([])
  const [IncomeData, setIncomeData] = useState([])
  const [StateData, setStateData] = useState([])
  const [DistictData, setDistictData] = useState([])
  const [TalukaData, setTalukaData] = useState([])

  const status = JSON.parse(sessionStorage.getItem("status"));


  const getEducationData = () => {
    axios.get(EducationMaster)
      .then((res) => {
        if (res.data !== null) {
          setEducationData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

    const getSubeducationData = () => {
      axios.get(`${SubeducationMaster}${Education}`) 
      // axios.get("http://192.168.29.114:8091/api/subcast/select")
        .then((res) => {
          if (res.data !== null) {
            setSubeducationData(res.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

  const getSubCastData = () => {
    axios.get(subcastMaster)
      .then((res) => {
        if (res.data !== null) {
          setSubCastData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getIncomeData = () => {
    axios.get(IncomeMaster)
      .then((res) => {
        if (res.data !== null) {
          setIncomeData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getStateData = () => {
    axios.get(StateMaster)
      .then((res) => {
        if (res.data !== null) {
          setStateData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDistrictData = () => {

    axios.get(`${DistrictMaster}${State}`)

      .then((res) => {
        if (res.data !== null) {
          setDistictData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTalukaData = () => {

    axios.get(`${TalukaMaster}${State}/${District}`)

      .then((res) => {
        if (res.data !== null) {
          setTalukaData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    getEducationData();
    getSubeducationData();
    getSubCastData();
    getIncomeData();
    getStateData();

   }, [Education])
  useEffect(() => {
    getDistrictData()
    getTalukaData()

    }, [State, District])

    //================checkbox====================
  
    const handleCheckboxChange = (event, option) => {
      if (event.target.checked) {
        if (selectedOptions.length < 2) {
          setSelectedOptions([...selectedOptions, option]);
        } else {
          toast.error('You can select only two options!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          event.target.checked = false;
        }

      } else {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      }
    
      if (option === 'Farmer') {
        setFarmer(event.target.checked ? 1 : 0);
      }
      if (option === 'Businessman') {
        setBusinessman(event.target.checked ? 1 : 0);
      }
      if (option === 'Compatible') {
        setCompatible(event.target.checked ? 1 : 0);
      }
      if (option === 'HouseWife') {
        setHouseWife(event.target.checked ? 1 : 0);
      }
      if (option === 'Serviceman') {
        setServiceman(event.target.checked ? 1 : 0);
      }
  
      
    };


//-----------------------------------------------------------------

  const handleClose = () => {
   navigate("/admin/member/mp")
  };
  
//--------------------------------------CSS--------------------
  const info = {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    backgroundColor: "#b3b3d6",
    color: "black",
  }
 
  //=================================multiple select==========================================

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setEduCategory(selectedOptions);
  };

  
//============================================================================================================
  return (
    <>
    
    <div className="p-0">
        {' '}
        <AdminMasterHeader />{' '}
      </div>

      <div className='row p-0 m-0 pb-1 pt-2 editProfileTitle'>
          <div className="col-9 d-flex align-items-center justify-content-start ">
            <h3 style={{ fontWeight: '550',}}>Edit Member Profile</h3>
          </div>
          <div className='col-3 text-start text-md-end '>                      
            <button className='rounded '  onClick={handleClose}  style={{ padding: "5px 15px", backgroundColor: "#ffa500", border: "0px",  fontSize: "18px", }}>
              <b>Back</b>
            </button>                                      
        </div>
      </div>

        <form onSubmit={(event) => updatedata(event)}>
        
              <div className="row profile-details m-0 pt-4" style={{backgroundColor:"#d1d1d4"}}>
      

                <div className='col-12 col-md-3 pt-3'> 
                    <img  
                    style={{border:"1px solid",borderRadius:"10px",}}
                          src={`${memberImagePath}/${Image}`}
                          alt={`${Enfname}'s Photo`}
                            className="small-photo mb-2" /> 
                           

                            <div className='col-12 px-3 py-1'>
                              <label>Change Image :</label>
                                <Input type="file"  onChange={handleFileChange} />
                            </div>   

                    <img  
                    style={{border:"1px solid",borderRadius:"10px",}}
                          src={`${adharImagePath}/${adharImage}`}
                          alt={`${Enfname}'s Photo`}
                            className="small-photo mb-2" /> 
                           

                            <div className='col-12 px-3 py-1'>
                              <label>Change Image :</label>
                                <Input type="file"  onChange={handleFileChange1} />
                            </div>     

                </div>
                 
              

                <div className='col-12 col-md-9 pb-5'>
                
                  {/* Basic Information */}
                  <div className='row m-0 border mb-3 pb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px   grey" }}>
                
                      <div className='col-12 border-bottom mb-4' style={info}><h6>Basic Information/मुलभूत माहिती</h6></div>

                      <div className="row p-0 m-0">
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>First Name (English):</label>
                        <Input value={Enfname} onChange={(e) => setEnfname(e.target.value)} required />
                      </div>
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Middle Name(English):</label>
                        <Input value={Enmname} onChange={(e) => setEnmname(e.target.value)} required  />

                      </div>
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Last Name (English):</label>
                        <Input value={Enlname} onChange={(e) => setEnlname(e.target.value)} required />

                      </div>
                    </div>

                    <div className="row p-0 m-0">
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>पहिले नाव (मराठी):</label>
                        <Input value={Mrfname} onChange={(e) => setMrfname(e.target.value)} required  />
                      </div>
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>मधले नाव (मराठी):</label>
                        <Input value={Mrmname} onChange={(e) => setMrmname(e.target.value)} required />
                      </div>
                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>आडनाव (मराठी):</label>
                        <Input value={Mrlname} onChange={(e) => setMrlname(e.target.value)} required  />
                      </div>
                    </div>

                    <div className='row p-0 m-0' >

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Gender/लिंग:</label> <br/>
                                                <Select value={Gender} className='w-100'
                                                      onChange={(value) => setGender(value)}
                                                      required
                                                      >
                                                      <Option value="">Select Gender</Option>
                                                      <Option value="1">Male</Option>
                                                      <Option value="2">Female</Option>
                                                  </Select>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Marital Status/वैवाहिक स्थिती:</label> <br/>
                                                  <Select value={MaritialStatus} className='w-100'
                                                      onChange={(value) => setMaritialStatus(value)}
                                                      required
                                                      >
                                                      <Option value="">Select Marital Status</Option>
                                                      <Option value="1">Unmarried</Option>
                                                      <Option value="2">Divorcee</Option>
                                                      <Option value="3">Widow</Option>
                                                  </Select>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Complextion/रंग:</label> <br/>
                                                  <Select  value={Complextion} className='w-100'
                                                          onChange={(value) => setComplextion(value)}
                                                          required
                                                        >
                                                          <Option>Choose...</Option>
                                                          <Option value="गोरा">Fair - गोरा</Option>
                                                          <Option value="गहू">Brown - गहू </Option>
                                                          <Option value="सावळा">Dark - सावळा</Option>
                                                  </Select>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Disability/दिव्यांग:</label> <br/>
                                                <Select  value={Disability} className='w-100'
                                                    onChange={(value) => setDisability(value)}
                                                    required
                                                    >
                                                    <Option value="1">No</Option>
                                                    <Option value="2">Yes</Option>
                                                  </Select>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Date of birth/जन्मतारीख:</label>
                          <Input type='date'  value={Dateob} onChange={(e) => setDateob(e.target.value)} required/>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Birth time/जन्मवेळ:</label>
                          <Input type='time' value={BirthTime} onChange={(e) => setBirthTime(e.target.value)} required />
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                          <label>Height/उंची:</label> <br/>
                                            <Select value={Height}  className='w-100'
                                                    onChange={(value) => setHeight(value)}
                                                    data-validetta="required"
                                                    required>
                                                    <Option value="">Choose...</Option>
                                                    <Option value="Below 4 ft">Below 4ft
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 06 in">4ft 06in
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 07 in">4ft 07in
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 08 in">4ft 08in
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 09 in">4ft 09in
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 10 in">4ft 10in
                                                    </Option>
                                                    <Option value="4 ft &nbsp; 11 in">4ft 11in
                                                    </Option>
                                                    <Option value="5 ft">5ft
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 01 in">5ft 01in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 02 in">5ft 02in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 03 in">5ft 03in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 04 in">5ft 04in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 05 in">5ft 05in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 06 in">5ft 06in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 07 in">5ft 07in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 08 in">5ft 08in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 09 in">5ft 09in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 10 in">5ft 10in
                                                    </Option>
                                                    <Option value="5 ft &nbsp; 11 in">5ft 11in
                                                    </Option>
                                                    <Option value="6 ft">6ft
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 01 in">6ft 01in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 02 in">6ft 02in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 03 in">6ft 03in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 04 in">6ft 04in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 05 in">6ft 05in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 06 in">6ft 06in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 07 in">6ft 07in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 08 in">6ft 08in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 09 in">6ft 09in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 10 in">6ft 10in
                                                    </Option>
                                                    <Option value="6 ft &nbsp; 11 in">6ft 11in
                                                    </Option>
                                                    <Option value="7 ft">7ft
                                                    </Option>
                                                    <Option value="Above 7 ft">Above 7ft
                                                    </Option>
                                            </Select>
                        </div>

                        <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Subcast/पोटजाती:</label> <br/>
                                        <Select aria-label="Default select example"  className='w-100'
                                          value={SubCaste}
                                          onChange={(value) => setSubCaste(value)}
                                          required >
                                          <Option value="">Choose...</Option>
                                          {
                                            SubCastData.map((value, index) => {
                                              return (
                                                <Option value={value.id} key={index.id}>{value.subcastename}</Option>
                                              )
                                            }
                                            )}
                                       </Select>
                        </div>
                      </div>
                   
                  </div>

                  {/* Education Information */}
                  <div className='row m-0 border mt-3  pb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6></div>

                    <div className='row p-0 m-0'>

                      <div className='col-12 col-md-6 px-3 py-1'>
                        <label>Education/शिक्षण:</label>
                        <Autocomplete
                          multiple size='small'
                          id="movie-tags"
                          value={EduCategory}
                          onChange={(event, newValue) => {
                            setEduCategory(newValue);
                                          if (newValue.length <= 2) {
                                          }
                                          else{
                                            toast.warn('Please ,Select Only Two Options!', {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              theme:'colored'
                            });
                          }}}
                          options={SubeducationData}
                          getOptionLabel={(option) => option.subeducation_name}
                          renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                        <Chip
                          style={{ color: "black"}}
                          label={option.subeducation_name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Education" style={{ color: "black",marginBottom:"10px"}}/>
                          )}
                        />                                
                      </div>
                      <div className='col-12 col-md-6 px-3 py-1'>
                        <label>Education Category/शिक्षण श्रेणी:</label>
                                                  <Select className='w-100' style={{height:"48px"}}
                                                    value={Education}
                                                    filterSelectedOptions
                                                    onChange={(value) => setEducation(value)}
                                                    required>
                                                    <Option value="">Choose...</Option>
                                                    {
                                                      EducationData.map((value, index) => {
                                                        return (
                                                          <Option value={value.id} key={index.id}>{value.education_name}</Option>
                                                        )
                                                      }
                                                      )}
                                                  </Select>
                      </div>

                      <div className='col-12 col-md-6 px-3 py-1'>
                        <label>Occupation Type/व्यवसायाचा प्रकार:</label>
                                                  <Select aria-label="Default select example" className='w-100'
                                                      value={EduType}
                                                      onChange={(value) => setEduType(value)}
                                                      required>
                                                      <Option value="">Choose...</Option>
                                                      <Option value="Business" >Business</Option>
                                                      <Option value="Service" >Service</Option>
                                                      <Option value="Student" >Student</Option>
                                                      <Option value="Farmer" >Farmer</Option>
                                                      <Option value="Other" >Other..</Option>
                                                    </Select>
                      </div>
                      <div className='col-12 col-md-6 px-3 py-1'>
                        <label>Annual Income/वार्षिक उत्पन्न:</label>
                                              <Select aria-label="Default select example" className='w-100'
                                                value={AnnualIncome}
                                                onChange={(value) => setAnnualIncome(value)} 
                                                required>
                                                <Option value="">Choose...</Option>
                                                {
                                                  IncomeData.map((value, index) => {
                                                    return (
                                                      <Option value={value.id} key={index}>{value.amount}</Option>
                                                    )
                                                  }
                                                  )}
                                              </Select>
                      </div>
                    </div>
                    
                      <div className='col-12 col-md-6 px-3 py-1'>
                      <label>Occupation & Addrress/व्यवसाय आणि पत्ता:</label>
                        <Input value={OccupationDetail} onChange={(e) => setOccupationDetail(e.target.value)} required />
                      </div>
                   
                  </div>

                  {/* Family Information */}
                  <div className='row m-0 border mt-3  pb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Family Information/कौटुंबिक माहिती</h6></div>

                    <div className='row p-0 m-0'>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Father First Name(English):</label>
                        <Input type="text" value={Enmname} onChange={(e) => setFaFNameEn(e.target.value)} required />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Father Middle Name(English):</label>
                        <Input type="text" value={FaMNameEn} onChange={(e) => setFaMNameEn(e.target.value)} required  />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Father Last Name(English):</label>
                        <Input type="text"  value={Enlname} onChange={(e) => setFaLNameEn(e.target.value)} required  />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>वडिलांचे नाव(मराठी):</label>
                        <Input  type="text" value={FaFNameMr} onChange={(e) => setFaFNameMr(e.target.value)} required/>
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>वडिलांचे मधले नाव(मराठी):</label>
                        <Input type="text" value={FaMNameMr} onChange={(e) => setFaMNameMr(e.target.value)} required />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>वडिलांचे आडनाव(मराठी):</label>
                        <Input type="text" value={FaLNameMr} onChange={(e) => setFaLNameMr(e.target.value)} required  />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Farm in Acar/शेत जमीन एकर:</label>
                                              <Select aria-label="Default select example" className='w-100'
                                                  value={FarmAcar}
                                                  onChange={(value) => setFarmAcar(value)}
                                                  required>
                                                  <Option value="">Choose... </Option>
                                                  <Option value="0">0</Option><Option value="1">1</Option>
                                                  <Option value="2">2</Option><Option value="3">3</Option>
                                                  <Option value="4">4</Option><Option value="5">5</Option>
                                                  <Option value="6">6</Option><Option value="7">7</Option>
                                                  <Option value="8">8</Option><Option value="9">9</Option>
                                                  <Option value="10">10</Option><Option value="11">11</Option>
                                                  <Option value="12">12</Option><Option value="13">13</Option>
                                                  <Option value="14">14</Option><Option value="15">15</Option>
                                                  <Option value="16">16</Option><Option value="17">17</Option>
                                                  <Option value="18">18</Option><Option value="19">19</Option>
                                                  <Option value="20">20</Option><Option value="21">21</Option>
                                                  <Option value="22">22</Option><Option value="23">23</Option>
                                                  <Option value="24">24</Option><Option value="25">25</Option>
                                                  <Option value="26">26</Option><Option value="27">27</Option>
                                                  <Option value="28">28</Option><Option value="29">29</Option>
                                                  <Option value="30">30</Option><Option value="31">31</Option>
                                                  <Option value="32">32</Option><Option value="33">33</Option>
                                                  <Option value="34">34</Option><Option value="35">35</Option>
                                                  <Option value="36">36</Option><Option value="37">37</Option>
                                                  <Option value="38">38</Option><Option value="39">39</Option>
                                                  <Option value="40">40</Option><Option value="41">41</Option>
                                                  <Option value="42">42</Option><Option value="43">43</Option>
                                                  <Option value="44">44</Option><Option value="45">45</Option>
                                                  <Option value="46">46</Option><Option value="47">47</Option>
                                                  <Option value="48">48</Option><Option value="49">49</Option>
                                                  <Option value="50">50</Option><Option value="above 50">Above 50...</Option>
                                                </Select>
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Farm in Guntha/शेत जमीन गुंठे:</label>
                               <Select aria-label="Default select example" className='w-100'
                                          value={FarmGuntha} 
                                          onChange={(value) => setFarmGuntha(value)}
                                          required>
                                          <Option value="">Choose... </Option>
                                          <Option value="0">0</Option><Option value="1">1</Option>
                                          <Option value="2">2</Option><Option value="3">3</Option>
                                          <Option value="4">4</Option><Option value="5">5</Option>
                                          <Option value="6">6</Option><Option value="7">7</Option>
                                          <Option value="8">8</Option><Option value="9">9</Option>
                                          <Option value="10">10</Option><Option value="11">11</Option>
                                          <Option value="12">12</Option><Option value="13">13</Option>
                                          <Option value="14">14</Option><Option value="15">15</Option>
                                          <Option value="16">16</Option><Option value="17">17</Option>
                                          <Option value="28">18</Option><Option value="19">19</Option>
                                          <Option value="20">20</Option><Option value="21">21</Option>
                                          <Option value="22">22</Option><Option value="23">23</Option>
                                          <Option value="24">24</Option><Option value="25">25</Option>
                                          <Option value="26">26</Option><Option value="27">27</Option>
                                          <Option value="28">28</Option><Option value="29">29</Option>
                                          <Option value="30">30</Option><Option value="31">31</Option>
                                          <Option value="32">32</Option><Option value="33">33</Option>
                                          <Option value="34">34</Option><Option value="35">35</Option>
                                          <Option value="36">36</Option><Option value="37">37</Option>
                                          <Option value="38">38</Option><Option value="39">39</Option>
                                </Select>
                      </div>

                      <div className='col-12 px-3 py-1'>
                        <label>Other Property/इतर मालमत्ता:</label>
                        <Input  type="text" value={OtherProperty} onChange={(e) => setOtherProperty(e.target.value)} required  />
                      </div>

                    </div>


                    <div className='row p-0 m-0'>

                      <div className='col-12 col-md-6 px-3 py-1'>
                        <div className='row'>
                            <div className='col-12 col-md-2 py-1'><label>Brother/भाऊ:</label></div>
                            <div className='col-12 col-md-5 py-1'>Unmarried/अविवाहित  <Input type='number' value={UnMarriedBro}   onChange={(e) => setUnMarriedBro(e.target.value)}  required /> 
                            </div>
                            <div className='col-12 col-md-5 py-1'>Married/विवाहित  <Input  type='number' value={MarriedBro} onChange={(e) => setMarriedBro(e.target.value)} required  /> 
                            </div>
                        </div>
                      </div>
                  
                      <div className='col-12 col-md-6 px-3 py-1'>
                        <div className='row'>
                            <div className='col-12 col-md-2 py-1'><label>Sister/बहीण:</label></div>
                            <div className='col-12 col-md-5 py-1'>Unmarried/अविवाहित  <Input type='number' value={UnMarriedSis}  onChange={(e) => setUnMarriedSis(e.target.value)} required />
                              </div>
                            <div className='col-12 col-md-5 py-1'>Married/विवाहित  <Input type='number' value={MarriedSis}  onChange={(e) => setMarriedSis(e.target.value)} required  /> 
                            </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  {/* Contact Information */}
                  <div className='row m-0 border mt-3  pb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}> 
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Contact Information/संपर्क माहिती</h6></div>

                    <div className='row p-0 m-0'>

                      <div className='col-12 col-md-7 px-3 py-1'>
                        <label>Address(English):</label>
                        <Input value={AddressEn} onChange={(e) => setAddressEn(e.target.value)} required />
                        
                      </div>

                      <div className='col-12 col-md-5 px-3 py-1'>
                        <label>Village/City(English):</label>
                        <Input value={CityEn} onChange={(e) => setCityEn(e.target.value)}   required /> 
                        
                      </div>

                      <div className='col-12 col-md-7 px-3 py-1'>
                        <label>पत्ता(मराठी):</label>
                        <Input  value={AddressMr} onChange={(e) => setAddressMr(e.target.value)} required />
                        
                      </div>

                      <div className='col-12 col-md-5 px-3 py-1'>
                        <label>गाव/शहर(मराठी):</label>
                        <Input value={CityMr} onChange={(e) => setCityMr(e.target.value)} required />
                        
                      </div>

                    </div>

                    <div className='row p-0 m-0 py-1'>

                     <div className='col-12 col-md-4 px-3 py-1'>
                        <label>State(राज्य):</label>
                                            <Select className='w-100' aria-label="Default select example" value={State}
                                                  onChange={(value) => setState(value)}
                                                  required>
                                                  <Option value="">Choose Option</Option>
                                                  {StateData.map((value, index) => {
                                                    return (
                                                      <Option value={value.id} >{value.stateenname}:{value.statemrname}</Option>
                                                    )
                                                  })
                                                  }
                                            </Select>
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>District(जिल्हा):</label>
                                            <Select className='w-100' aria-label="Default select example" value={District}
                                                    onChange={(value) => setDistrict(value)}
                                                    required>
                                                    <Option>Choose Option</Option>
                                                    {DistictData.map((value, index) => {
                                                      return (
                                                        <Option value={value.id} >{value.districtenname}:{value.districtmrname}</Option>
                                                      )
                                                    })
                                                    }
                                            </Select>
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                       <label>Taluka(तालुका):</label>
                                                <Select className='w-100' aria-label="Default select example" value={Taluka}
                                                              onChange={(value) => setTaluka(value)}
                                                              required>
                                                              <Option value="">Choose Option</Option>
                                                              {TalukaData.map((value, index) => {
                                                                return (
                                                                  <Option value={value.id} >{value.talukaenname}:{value.talukamrname}</Option>
                                                                )
                                                              })
                                                              }
                                                </Select>
                      </div>

                    </div>

                    <div className='row p-0 m-0 py-1'>

                    <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Email/ईमेल:</label>
                        <Input  type="email" value={Email} onChange={(e) => setEmail(e.target.value)} required/> 
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label>Mobile No-1/मोबाईल नंबर-1:</label>
                        <Input  type="tell" pattern="[0-9]{10}" maxLength="10" value={Mobno1} onChange={(e) => setMobno1(e.target.value)} required />
                      </div>

                      <div className='col-12 col-md-4 px-3 py-1'>
                        <label style={{ lineHeight: "18px" }}>Mobile No-2/मोबाईल नंबर-2:</label>
                        <Input  pattern="[0-9]{10}" type="tell" maxLength="10" value={Mobno2} onChange={(e) => setMobno2(e.target.value)} required />
                      </div>

                    </div>

                  </div>

                  {/* Expectations about spouse */}

                  <div  className='row m-0 border mt-3  pb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                  <div className='col-12 border-bottom mb-3' style={info}><h6>Expectations about spouse -/जोडीदाराबाबत अपेक्षा</h6></div>
                  { Gender === 1 ? (
                  <Col sm={12} md={3}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                  
                  <input
                      type='checkbox'
                      name='HouseWife'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'HouseWife')
                      }  
                      defaultChecked={selectedOptions}
                     checked={selectedOptions.includes('HouseWife')}
                    />
                    <h6 className='ms-2 pt-2'>Homemaker/ गृहिणी</h6>
                  </label>
                </Col>
              ) : null}

                  <Col sm={12} md={3}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                        <input
                      type='checkbox'
                      name='compatible'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'Compatible')
                      }
                      defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Compatible')}
                    />
                    <h6 className='ms-2 pt-2'>Compatible/अनुरूप</h6>
                  </label>
                </Col>
                  <Col sm={12} md={2}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        type='checkbox'
                        name='businessman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Businessman')
                        }
                        defaultChecked={selectedOptions}
                        checked={selectedOptions.includes('Businessman')}
                      />
                      <h6 className='ms-2 pt-2'>Business/व्यवसाय</h6>
                    </label>
                  </Col>
                  <Col sm={12} md={2}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      type='checkbox'
                      name='serviceman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Serviceman')
                        }
                        defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Serviceman')}
                    />
                    <h6 className='ms-2 pt-2'>Service/नोकरी</h6>
                  </label>
                </Col>
                <Col sm={12} md={2}>
                  <label
                    className='pt-1 pb-md-3'
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='farmer'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Farmer')
                    }
                    checked={selectedOptions.includes('Farmer')}
                  />
                  <h6 className='ms-2 pt-2'>Farmer/शेतकरी</h6>
                </label>
              </Col>
            </div>


                    {/* Buttons */}
                  <div className='row p-0 d-flex m-0 mb-md-3' style={{justifyContent:"center",textAlign:"end",}}>
                    <div>
                        <div className='mt-4' style={{ display: "flex", justifyContent: "end", }}>
                                <button  type='submit' className='rounded' style={{ padding: "8px 22px", backgroundColor: "orange", color: "black",border:"none", marginBottom: "30px", fontSize: "18px" }} > <b>Update Data</b></button>
                             
                        </div>
                    </div>
                   
                </div>
                 
                </div>
               
              </div>

        </form>

        <AdminMasterFooter/>

    </>
  )
}

export default EditMainPage