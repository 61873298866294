import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
// import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HashRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <HashRouter>

    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>

  </HashRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
