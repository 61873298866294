import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RegistrationSelect, RegistrationUpdate, RegistrationUpdateProfile } from '../../../AllApi/UserApi/UserApi';
import { adminRegsitrationSecondPageSave } from '../../../AllApi/AdminApi/AdminApi';

const SecondPageAdmin = (props) => {


     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  const [selectedOptions, setSelectedOptions] = useState([]);
  const [update, setUpdate] = useState(false);

  const data = JSON.parse(sessionStorage.getItem('status'));

  const [Compatible, setCompatible] = useState(0);
  const [Businessman, setBusinessman] = useState(0);
  const [Serviceman, setServiceman] = useState(0);
  const [Farmer, setFarmer] = useState(0);
  const [HouseWife, setHouseWife] = useState(0);

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const memberdata = JSON.parse(sessionStorage.getItem('memberdata'));
  console.log(memberdata);
  
  const member_id = memberdata.member_id;

  const handleSave = (e) => {
    setIsLoading(true)
    e.preventDefault();
    const regdata = {
      member_id: member_id,
      exp_compatible: Compatible,
      exp_businessman: Businessman,
      exp_serviceman: Serviceman,
      exp_farmer: Farmer,
      exp_housewife: HouseWife,
    };
    console.log("Expdata=========",regdata)

    axios
      .put(adminRegsitrationSecondPageSave, regdata)
      .then((res) => {
        setIsLoading(false); 
        console.log("saved data============ ",res.data)
        if (res.data.message === 'UPDATED') {
         
          toast.success('Saved Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.setItem("status", 5);
          props.onSubmit();
        }
      })
      .catch((err) => console.log(err));
  };

 
  const [userStatus, setUserStatus] = useState(null);



  const handleCheckboxChange = (event, option) => {
    if (event.target.checked) {
      if (selectedOptions.length < 5) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        // alert('You can select only two options.');
        toast.error('You can select only two options.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        event.target.checked = false;
      }
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  
    if (option === 'Farmer') {
      setFarmer(event.target.checked ? 1 : 0);
    }
    if (option === 'Businessman') {
      setBusinessman(event.target.checked ? 1 : 0);
    }
    if (option === 'Compatible') {
      setCompatible(event.target.checked ? 1 : 0);
    }
    if (option === 'HouseWife') {
      setHouseWife(event.target.checked ? 1 : 0);
    }
    if (option === 'Serviceman') {
      setServiceman(event.target.checked ? 1 : 0);
    }

    
  };

  return (
    <>
      <Container className='container-second rounded p-3 my-3 p-md-5'>
       
        <Form onSubmit={handleSave}>
          <div
            className=' rounded shadow p-3 pb-2 py-sm-5 '
            style={{ backgroundColor: 'white' }}
          >
            <Row style={{ paddingTop: '10px' }}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: '',
                    paddingBottom: '10px',
                    borderBottom: '1px solid gray',
                    color: 'rgb(134, 28, 18)',
                  }}
                  

                >
                  <h5>
                    <b>Expectations about spouse - जोडीदाराबाबत अपेक्षा</b>
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingTop: '10px',
                    color: 'rgb(134, 28, 18)',
                  }}
                >
                </div>
              </Col>
            </Row>
            <Row className='px-2' style={{ paddingTop: '10px' }}>
              {memberdata && memberdata.gender == 1 ? (
                <Col sm={12} md={3}>
                  <label
                    className='mx-4 pt-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                  
                    <input
                      type='checkbox'
                      name='HouseWife'
                      value='1'
                      onChange={(event) =>
                      handleCheckboxChange(event, 'HouseWife')}  
                      defaultChecked={selectedOptions}
                     checked={selectedOptions.includes('HouseWife')}
                    />
                    <h5 className='ms-2'>Homemaker/ गृहिणी</h5>
                  </label>
                </Col>
              ) : null}
              <Col sm={12} md={3}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='Compatible'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Compatible')
                    }
                    checked={selectedOptions.includes('Compatible')}
                  />
                  <h5 className='ms-2'>Compatible/अनुरूप</h5>
                </label>
              </Col>
              <Col sm={12} md={3}>
                <label
                  className='mx-4 pt-3 '
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='businessman'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Businessman')
                    }
                    checked={selectedOptions.includes('Businessman')}
                  />
                  <h5 className='ms-2'>Business/व्यवसाय</h5>
                </label>
              </Col>
              <Col sm={12} md={3}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='serviceman'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Serviceman')
                    }
                    checked={selectedOptions.includes('Serviceman')}
                  />
                  <h5 className='ms-2'>Service/नोकरी</h5>
                </label>
              </Col>
              {memberdata && memberdata.gender == 2 ? (
              <Col sm={12} md={3}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='farmer'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Farmer')
                    }
                    checked={selectedOptions.includes('Farmer')}
                  />
                  <h5 className='ms-2'>Farmer/शेतकरी</h5>
                </label>
              </Col>
              ) : null}
            </Row>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'rgb(134, 28, 18)',
                  paddingTop: '30px',
                }}
              >
                  <button
                    type='submit'
                    className='rounded py-2 px-4'
                    style={{
                      backgroundColor: '#462f46',
                      border: '0px',
                      color: 'white',
                      fontSize: '20px',
                    }}
                  >
                    Save
                  </button>
              </Col>
            </Row>
          </div>
        </Form>
        
      </Container>
    </>
  );
};

export default SecondPageAdmin
