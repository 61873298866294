import React, { useEffect, useState } from 'react';
import logonew from '../assets/logonew.jpg';
import {useNavigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AdminMasterFooter from './AdminMasterFooter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets//kolLogo.png';
import Logo2 from '../assets/logo2.png';
import { currentYearData } from '../AllApi/AdminApi/AdminApi';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

const AdminMasterHeader = () => {

  const [melavaYear, setMelavaYear] = useState('');
  const navigate=useNavigate()
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const checkLogin = () => {
    const getSessionval = sessionStorage.getItem('userdata')
    if (!getSessionval) {
      navigate('/')
    }
   
  }

  const fetchMelavaYear = async () => {
    try {
      const response = await axios.get(currentYearData);       
      setMelavaYear(response.data[0].melava_year); 
    } catch (error) {
      console.error('Error fetching melavayear:', error);
    }
  };

  useEffect(() => {
    checkLogin()
    fetchMelavaYear();
  }, [])


  const handleLogout = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/admin");    
  }

  const handleShowLogoutModal = () => setShowLogoutModal(true);
  // Function to hide the modal without logging out
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  // Function to confirm logout and clear the session
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseLogoutModal();
  };

  
      
  return (
    <>

        <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 bg-white'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी {melavaYear}</h5>
        </div>
        </Col> 
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={110}/>
            </div> 
       </Col> 
        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={handleShowLogoutModal}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#462f46",paddingBottom:"15px"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233,224,227)",}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto"
            navbarScroll
          >
            <Nav.Link href="/#/admin/dashboard" className=' mx-2 text-white'>Home</Nav.Link>

            <NavDropdown  id="basic-nav-dropdown" className=' mx-2 text-white'
              title={<span className="text-white my-auto">Members</span>}>
              <NavDropdown.Item href="/#/admin/member/mp">Member Profiles</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/pa">Photo Approvals</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/payapp">Payment Approval</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/im">Inactive Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/ppm">Payment Pending Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/psm">Payment Success Members</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/#/admin/ad-regForm" className=' mx-2 text-white'>Member Entry</Nav.Link>
            <Nav.Link href="/#/admin/r1" className=' mx-2 text-white'>Report</Nav.Link>
            <Nav.Link href="/#/scanPage" className=' mx-2 text-white'>Entry Pass</Nav.Link>
            

          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>


      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={handleCloseLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogoutModal}>
            Cancel
          </Button>
          <Button style={{backgroundColor:"#462f46",border:"1px solid #462f46"}} onClick={handleConfirmLogout}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

       

    </Container> 
    </div>
   
    </>
  )
}

export default AdminMasterHeader