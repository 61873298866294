import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './Login1.css'
import {useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo2 from './assets/kolLogo.png';
import kollogo from './assets/kolLogo.png';
import beforelogo from './assets/login-before.png'
import Registartion1 from './UserComponents/LoginNavComponents/Registration1';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { BaseIP, Forgetuser, RegistrationStop, WebsiteMessage } from './AllApi/UserApi/UserApi';
import { Form, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';



function Login1() {

  const navigate=useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   
        //Modal 
        const [show, setShow] = useState(false);
        const [show1, setShow1] = useState(false);
        const handleClose = () => setShow(false);
        const handleClose1 = () => setShow1(false);


const [lockDateOk,setlockDateOk]=useState(false)    
const [mobileNumber,setMobileNumber] =useState('');
const [password,setPassword] =useState('')
const [data, setData] = useState()

// =========== term & condition ==============
const [show3, setShow3] = useState(false);

const handleClose3 = () => setShow3(false);
const handleShow3 = () => setShow3(true);
const [websiteMessage, setWebsiteMessage] = useState([]); 
const fetchWebsiteMessage = async () => {
  try {
    const response = await fetch(WebsiteMessage);
    const data = await response.json();
    setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
  } catch (error) {
    console.error('Error fetching website message:', error);
  }
};

useEffect(() => {
  fetchWebsiteMessage();
}, []);

const saveFun=(e)=>{
    e.preventDefault();

    if(lockDateOk===true){
      alert("Registration For Supplement Book Will Be Started On 24-Nov-2024 , पुरवणी पुस्तिका नोंदणी 24 नोव्हेंबर 24 ला सुरु होईल")
            }
            else{


    if(!mobileNumber){
        toast.error('Mobile Number must not be empty', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
      else if(!password){
        // alert("Password must not be empty.")
        toast.error('Password must not be empty', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
   
    

    const saveObj ={
      mob_no:mobileNumber,
        password:password      
        
    }   

    axios.post(`${BaseIP}api/member/memberlogin`,saveObj)
    .then((res)=>{
      console.log("In login+++++",res.data)
        if(res.data ==="NOTFOUND"){
            // alert("Invalid login details")
            toast.error('Invalid login details', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
        }
        else if(res.data !==null){
            console.log("In else blog",res.data);
            sessionStorage.setItem("token", JSON.stringify(res.data.token));
            sessionStorage.setItem("userdata", JSON.stringify(res.data.userdata));
            sessionStorage.setItem("status", JSON.stringify(res.data.status));
            //  setIsUser(true)
            console.log("userdata=== ",res.data.userdata);
            console.log("Status== ",res.data.status);
            if(res.data.status === 3){
              navigate('/userDashboard')
            }
            else if(res.data.status === 4){
              navigate('/user/regForm')
            }
             else if(res.data.status === 5){
              navigate('/user/regForm')
             }
            else if(res.data.status === 6){
              navigate('/payment')
            }
            else if(res.data.status === 7){
              // navigate('/payment2')
              toast.warn('Please wait for payment approval from the admin.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            else if(res.data.status === 8){
              navigate('/mainpage')
            }
            else if(res.data.status === 9){
              navigate('/payment')
            }
            else{
              navigate('/')
            }
        }
    })
    .catch((err)=>{
        console.log('Err=='+err)
        toast.error('Server Error', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    })
    
}}

    // useEffect(() => {
    //   checkLogin()
    // }, [])

    const regFun=()=>{
      if(lockDateOk===true){
alert("Registration For Supplement Book Will Be Started On 24-Nov-2024 , पुरवणी पुस्तिका नोंदणी 24 नोव्हेंबर 23 ला सुरु होईल")
      }
      else{

      navigate('/registration1')
      // <Registartion1 />
          }    }

    const forgotFun =()=>{
      navigate('/user/forgotpassword')
    }


    // const navigate = useNavigate();
    // const isLoggedIn = !!sessionStorage.getItem("userdata");
    // useEffect(() => {
    //   if (!isLoggedIn) {
    //     navigate("/");
    //   }
    // }, [isLoggedIn, navigate]);

    useEffect(() => {
      let sessionData1=sessionStorage.getItem("userdata")
      let sessionData2=sessionStorage.getItem("token");

      if(sessionData1 !==null && sessionData2 !==null){
        navigate("/userDashboard")
      }
    }, [])

    //--------------- forgotpassword---------------------------//

      const [forgetshow,setForgetshow]=useState()
      const [forgetnum,setForgetnum]=useState()
      const [dateofbirth,setdateofbirth]=useState()
      const handleforgetshow = () => {
        setForgetshow(true)
        // setLogshow(false)
      };
      const handleforgetClose = () => setForgetshow(false);
      const handlenumforChange = (e) => {
        setForgetnum(e.target.value);
       
      };
      const handledobforChange = (e) => {
        
        setdateofbirth(e.target.value);
      };

      const handleforgetlog=(event)=>{
        event.preventDefault();
        const forgotdata={
          mob_no:forgetnum,
          dateofbirth:dateofbirth
        }
         
      console.log("Enail data",forgotdata);
        axios.put(Forgetuser,forgotdata)
        .then((res) => {
            if (res.data.status === "SUCCESS") {
              toast.success('Your Password Has Been Sent On Email', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            } 
            else if(res.data==="ERROR") {
              toast.error('Cheack Your Enter mobile number', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            }
            // else if(res.data==="FAILED"){
            //   alert("Failed To Send Email");
            //   setForgetshow(false)
              
            // }
            else {
              toast.error('Server Error', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              
            }
          })
          .catch((err) => {
              // alert("Failed To Send Email, try again");
              toast.error('Failed To Send Email, try again', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setForgetshow(false)
            });

}

          // const Getdata = (e) => {
          //   axios
          //     .get(RegistrationStop)
          //     .then((res) => {
          //       if (res.data !== null) {
          //         console.log("++++",res.data);
          //         const locadat=res.data
          //         const currentDate = new Date();
          //       const formattedDate = currentDate.toISOString().split('T')[0];
          //       console.log(formattedDate);
          //         if(formattedDate>locadat){
          //           setlockDateOk(true)
          //         }

          //       }
          //     })
          //     .catch((err) => {
          //       toast.error("Failed to upload, try again", {
          //         position: "top-right",
          //         autoClose: 3000,
          //         hideProgressBar: false,
          //         closeOnClick: true,
          //         pauseOnHover: true,
          //         draggable: true,
          //       });
          //       console.log(err);
          //     });
          // };

          // useEffect(() => {
          //   Getdata();
          // }, []);

    

  return (<>
    <Container fluid className='p-0 '>
     
      <Row className='wrap pb-5 m-0 px-md-5 pt-3'>
        <Col className='m-0 p-0' md={12} sm={12}>
        <div>
       <marquee scrollamount="11" style={{display:"flex"}}>
          <h4 className='fw-bolder pt-2'>{websiteMessage.marquee_message}</h4>
        </marquee> 
       </div>
       </Col>


        <Col md={6} style={{display:"flex",justifyContent:"center"}}>
       <Col  style={{color:"rgb(57, 29, 28)"}}>
       
       <div className='text-center'>
                <img src={Logo2} style={{mixBlendMode:"darken"}} className='mt-2 mb-2' alt="" width={100} height={100}/>
            </div> 

       <div className=' text-center pt-3' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h4 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h4>
              <h3 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h3>
              <h4 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h4>
            </div>


            <Row>
          <Col sm={12} md={12}  style={{display:"flex",justifyContent:"center"}}>
            <div className='py-2 px-3 mt-4 rounded shadow text-center' onClick={handleShow3} style={{backgroundColor:"rgb(250, 99, 18)",color:"black"}}><b><u style={{color:"blue"}}>मेळावा नियम व अटी</u></b></div>
          </Col>
          <Col sm={12} md={12}  style={{display:"flex",justifyContent:"center"}}>
            <div className='py-2 px-3 mt-4 rounded shadow text-center' style={{backgroundColor:"rgb(250, 99, 18)",color:"black"}}><b>सिंगल मेळावा {websiteMessage.first_melavadate}.</b></div>
          </Col>         
          <Col sm={12} md={12}  style={{display:"flex",justifyContent:"center"}}>
            <div className='py-2 px-3 mt-4 rounded shadow text-center' style={{backgroundColor:"rgb(250, 99, 18)",color:"black"}}><b>रजिस्ट्रेशन अंतिम ता. {websiteMessage.reg_enddate}</b></div>
          </Col>           
          <Col sm={12} md={12} className='pt-2 mt-3 pt-md-0' style={{display:"flex",justifyContent:"center"}}>
           <div className='py-2 px-3 rounded shadow text-center' style={{backgroundColor:"rgb(250, 99, 18)",color:"black"}}><b>घटस्फोटीत, विधवा, विधूर व अपंग स्वतंत्र मेळावा {websiteMessage.second_melavadate}</b></div>
          </Col>
        </Row>
        
        </Col> 
        </Col>   
          

        <Col md={6}>
        <div className='m-0 p-0 mt-3 mt-md-0 text-center' style={{display:"flex",justifyContent:"center"}}>
         <Nav className='text-center'>  
            <Nav.Link  className='text-white ' onClick={() => setShow(true)}><p className='text-dark' >Terms & Condition</p></Nav.Link>
            <Nav.Link  className='text-white ' onClick={() => setShow1(true)}><p  className='text-dark' >Privacy Policy</p></Nav.Link>
          </Nav> 
         </div>
         <div className='login-container'>     
        
         <div className='login-form'>
         
            <form onSubmit={saveFun}>
                <div className='input'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                       <img  src={beforelogo}/>
                    </div>
                    
                    <h1 style={{fontFamily:"cursive",color: "rgb(250, 99, 18)"}}>Login</h1>
                    <input id='input' maxlength="10"
                        title='Please enter 10 digit num.' value={mobileNumber} type="tel" placeholder='Enter Mobile Num.' onChange={(e)=>setMobileNumber(e.target.value)} required/>

                    <input id='input1' value={password} type="password" placeholder='Password' onChange={(e)=> setPassword(e.target.value)}  required/>

                    <div className='login-button'>
                    <button type="submit"  className='login-btn' >LOGIN</button>
                    </div>
                </div>

                <div  className='pt-3' style={{display:"flex",justifyContent:"end",color:"black"}}>
                    <Nav.Link   onClick={() => setForgetshow(true)}><small className='forgot'>Forgot Password?</small></Nav.Link>
                </div>

              
            </form> 
            <div className='register-button'>
                <button className='register-btn gif-button' onClick={regFun}  style={{textDecoration:"none",color:"white"}}>New User <br/> Create Account</button>
            </div> 
          </div>
        </div>
       </Col>
    </Row>
 

      {/* -----------------footer login --------------------------- */}
    <div className='m-0'>
    <Container fluid className='footerimg footer1-container p-md-2 m-0 px-md-2' style={{boxSizing:"border-box"}}>
      <Row className='m-0 py-1'>
        <Col sm={12} md={5} className='text-white text-center  py-3 '>
        <div >
        <img style={{height:"80px",width:"80px"}} src={kollogo}/>
        </div> 
        <div className='text-center pt-3' style={{color: "white"}}>
              <h6 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h6>
              <h5 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h5>
              <h6 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start '>
            <h4  style={{color:"rgb(250, 99, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start '>
            <Link onClick={handleShow3}
                  style={{textDecoration:"none", color:"white" }} >
                   <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>   
                    मेळावा नियम व अटी
            </Link><br/>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white" }} >
                   <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/>
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Admin(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start '>
            <h4  style={{color: "rgb(250, 99, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start'>
           
            <a href="mailto:djbkkop@gmail.com" style={{textDecoration: "none",color:"white" }}>
              <i style={{ paddingRight: "10px",color: "rgb(250, 99, 18)" }} className="fa-solid fa-envelope"></i>   
              Email : <span>djbkkop@gmail.com</span>
              </a>
              <br/>
              <a href="tel:2312644339" style={{ textDecoration: "none" ,color:"white"}}>
              <i style={{ paddingRight: "10px",color: "rgb(250, 99, 18)" }} className="fa-solid fa-phone"></i> 
              Contact: 0231 2644339
            </a><br/>
            </div>
        </Col>
        {/* <Col sm={12} md={4} className='text-light pt-5' style={{fontSize:"5px"}}>
            <p>मेळावा पुस्तिका रजिस्ट्रेशन सुरु ता. 1 ऑक्टोबर 2023</p><br/>
            <p>मेळावा पुस्तिका रजिस्ट्रेशन अंतिम ता. 20 नोव्हेंबर 2023</p><br/>
            <p>मेळावा ता. 24 डिसेंबर 2023</p><br/>
            <p>पुरवणी पुस्तिका रजिस्ट्रेशन सुरु ता. 23 नोव्हेंबर 2023</p><br/>
            <p>पुरवणी पुस्तिका रजिस्ट्रेशन अंतिम ता. 22 डिसेंबर 2023</p>
        </Col> */}
      </Row>
      <Row className='m-0 p-0'>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(250, 99, 18) "}}>
            &copy; {websiteMessage.melava_year} Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
    </div>
    </Container>

                {/*//Forget PassWord Modal */}

     <Modal show={forgetshow} onHide={handleforgetClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Fill Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form  onSubmit={handleforgetlog}>
            <Row className="mb-3">
              <Form.Group md="4" controlId="validationCustom01">
                <Form.Label>Enter Mobile Number</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Mobile Number"
                  onChange={handlenumforChange}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Mobile Number Please
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group md="4" controlId="validationCustom01">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Enter Mobile Number"
                  onChange={handledobforChange}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Mobile Number Please
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button type="submit">Send Now</Button>
          </Form>
        </Modal.Body>
      </Modal>


       
        {/*   --------------Terms & Condition Modal-------------- */}
    <Modal
    show={show}
    onHide={handleClose}
    dialogClassName="modal-100w"
    size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
  >
    <Modal.Header  closeButton style={{backgroundColor: "rgb(233, 224, 227)"}}>
      <Modal.Title id="example-custom-modal-styling-title" style={{color: "rgb(250, 99, 18)"}}>
        Terms & Condition
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
      These terms and conditions (“Terms and Conditions”) control your use of this website: www. jainboardingkolhapur.com (Website).
      </p>
      <p>
      You refers to a user or a paying customer. If You are a company or another person who gives access to Company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.
      </p>
      <p>
      By using or accessing the information on this website, including, but not limited to downloading or accessing courses through this Website or through designated Software of the Company; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:
      </p>
      <h5>GENERAL TERMS OF USE</h5>
      <p>
      Registering at this Website does not entitle You to the right of admission/selection for any course. The Company Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.
      </p>
      <p>
      Selected candidates are required to comply with the rules laid down under the various Policies and jainboarding kolhapur, reserves the right to expel a student or terminate the candidature at any time if found otherwise.
      </p>
      <p>
      As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.
      </p>
      <p>
      You will provide current, complete, accurate information in the registration section of this Website. You will further update and keep that information current as needed. Email login must be a valid email address maintained by You. You are solely responsible for maintaining the confidentiality of your password and account information. You will immediately notify the Company of any unauthorized account activity, or any unauthorized use of your email list(s) or any other breach of security You become aware of by emailing at: djbkkop@gmail.com
      </p>
      <p>
      Excluding third party course materials, jainboarding kolhapur owns all copyrights, patents, trade secrets, trademarks and other intellectual property rights, title and interest in or pertaining to the courses in the course catalogue and all other services developed by jainboarding kolhapur for the purpose of these courses on offer. No resale, transfer or re-export of the course(s) and/or content is authorized without the prior authorization of jainboarding kolhapur.
      </p>
      <p>
      jainboarding kolhapur reserves the right to change course prices at any time. In the event of such change, confirmed registrations will be honored at the original price. Changes to Service Tax and other tax and duty components will be incorporated into the Course Fee as and when such changes occur and the student will become liable to pay the incremental fee.
      </p>
      <p>
      Course content for all the courses on offer is provided for the purpose of education and guidance only. The course content is regularly reviewed and is subjected to change without notice. jainboarding kolhapur reserves the right to modify training content without notice.
      </p>
      <p>
      jainboarding kolhapur may terminate your access at any time for any reason. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. jainboarding kolhapur may also monitor access to this Website.
      </p>
      <p>
      To the extent allowed by local law, in no event will jainboarding kolhapur or its affiliates, subcontractors or suppliers be liable for any incidental, indirect, special consequential damages.
      </p>
      <p>
      By registering on this Website, You give your consent to receive, from time to time, text messages from jainboarding kolhapur which may include offers from Us or our affiliates and partners.
      </p>
      <h5>ONLINE PAYMENT TERMS</h5>
      <p>
      The following section states the Terms and Conditions which will be applicable to the user using the Company’s on-line payment process for various courses/services offered by Us. Please read this page carefully. If these Terms & Conditions are not acceptable to You, please do not use this process. By using Company’s online payment process, You are indicating your acceptance to be bound by these Terms & Conditions. jainboarding kolhapur reserves the right to revise these Terms & Conditions from time to time.
      </p>
      <p>
      User information entered during the online payment process is kept secure and confidential and will not be divulged to any third party, other than the payment gateway service provider.
      </p>
      <p>
      The Company will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold the Company harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from incorrect use of the online payment process or any other causes attributable to the user.
      </p>
      <p>
      The Company will not be responsible for any refunds arising out of any failures in online payments in case the amount gets deducted from user’s saving/current account and does not get credited in the Company’s account, before the transaction is complete. Users in this case will have to contact either the Bank or Us to clarify the same.
      </p>
      <p>
      In no event will the user be eligible for the refund of the payment made online.
      </p>
      <p>
      All the refunds of credit & debit card transactions, which are subject to the Company’s policy, will be made by cheques only; no refund will be processed either by online transfer or in cash.
      </p>
      <p>
      Receipts of online payments along with confirmation of admissions by jainboarding kolhapur should be maintained by the user safely, as this will be a pre-condition for processing any refund.
      </p>
      <p>
      Successful completion of the online application process and payment alone will not make the user eligible for the admission. Since the online application system does not perform any check on the qualification of user or other essential conditions during the application process, hence, successful completion of the online application process may not make the user eligible for the course admission. The course admission will be totally at the discretion of jainboarding kolhapur and the same will be confirmed upon scrutiny of the admission details provided by the user and jainboarding kolhapur is not liable to give reason for any rejection.
      </p>
      <p>
      It is the user’s responsibility to ensure payment of full fees as applicable. Such payment of fees by or on behalf of the user does not in itself constitute acceptance of user’s candidature for the course. When the user successfully completes the online payment transaction using credit/debit cards option, funds will be deducted from the credit/debit card mentioned by the user and the payments will be credited to the Company. Any payment received through this website will be treated as having been made by the user, even though the same may have been made by some other person/party, in such event, the Company shall not be liable to entertain any correspondence from any person other than the user’s parents/guardian.
      </p>
      <p>
      In case the user registers successfully but fails to pay the required fee on or before the stipulated date, such application will stand cancelled without any further notification.
      </p>
      <p>
      The Company shall not be liable to protect data from third parties if there is any failure on the part of the user or any person acting on behalf of the user, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process.
      </p>
      <p>
      The Company will be entitled to use the information given during the online payment process for the purpose of registration of the user and also for statistical purposes or for its internal use.
      </p>
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: "rgb(233, 224, 227)"}}>
          <Button  onClick={handleClose} style={{backgroundColor: "rgb(250, 99, 18)", border:"1px solid rgb(250, 99, 18)"}}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>

          {/* PrivacyPolicy Modal */}

          <Modal
    show={show1}
    onHide={handleClose1}
    dialogClassName="modal-100w"
    size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
  >
    <Modal.Header closeButton style={{backgroundColor: "rgb(233, 224, 227)"}}>
      <Modal.Title id="example-custom-modal-styling-title" style={{color: "rgb(250, 99, 18)"}}>
        Privacy Policy
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
      We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.
      </p>
      <h5>What this Privacy Policy Covers?</h5>
      <p>
      This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
      <p>
      This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.
      </p>
      <h5>Information Collection and Use</h5>
      <p>
      We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.
      </p>
      <p>
      When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
      </p>
      <p>
      The e-mail or contact number address You submit to receive information from bduedu.in will never be disclosed to any third party. All e-mail that You receive will be from the domain names bduedu.in.
      </p>
      <p>
      Bharathidasan University has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that Bharathidasan University has developed for this Website are administrative, physical and technical barriers that together form a protective firewall around the information stored at this site.
      </p>
      <p>
      We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.
      </p>
      <p>
      We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.
      </p>
      <h5>Information Sharing and Disclosure</h5>
      <p>We will not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
      <p>
      We have your consent to share the information. <br/>
      We respond to subpoenas, court orders or legal process,;<br/>
      or We find that your actions on our web sites violate the Terms of Service
      </p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
      The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
      </p>
      <h5>Questions or Suggestions</h5>
      <p>If you have questions or suggestions send an email to us:</p>
      <p><a >djbkkop@gmail.com</a></p>
      
    </Modal.Body>
    <Modal.Footer style={{backgroundColor: "rgb(233, 224, 227)"}}>
          <Button  onClick={handleClose1} style={{backgroundColor: "rgb(250, 99, 18)", border:"1px solid rgb(250, 99, 18)"}}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>    

  {/* ===============melava term and condition ============== modal    */}

  <Modal size='xl' show={show3} onHide={handleClose3}>
        <Modal.Header closeButton style={{backgroundColor:"rgb(233, 224, 227)"}}>
          <Modal.Title style={{color: "rgb(250, 99, 18)"}}><b>मेळावा नियम व अटी</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row className='m-0 py-4 mb-3' style={{display:"flex",justifyContent:"center"}}>
            <Col sm={12} md={5} className='p-4 mb-2  mx-3 rounded shadow' style={{backgroundColor:"rgb(233, 224, 227)"}}>
              <h6><b style={{color:"red"}}>महत्वाची सूचना : परिचय पुस्तिका मराठी मधे छापली जाणार असले मुळे, मराठी रकाण्यात माहिती ही मराठीतच भरणे अनिवार्य आहे.</b></h6>
              <br/>
              <p>1) आपली संपूर्ण माहिती बरोबर असल्याची खातरजमा करा.</p>

              <p> 2) आपला फोटो HD कॉलीटीचा आयडेंटी साईज असावा.</p>  

              <p>3) फोटोचा बॅकग्राउंड सॉफ्ट कलर मध्ये असावा.</p>  

              <p> 4) फोटो मंजूर/नाकारणे याचा अधिकार आयोजकांकडे राहील.</p>

              <p> 5) तुम्ही रजिस्ट्रेशन फॉर्म मध्ये भरलेली माहिती (तपशील) आयोजकांकडून कोणतीही दुरुस्ती न करता आहे तशी पुस्तिकेत छापली जाईल.</p>  

              <p> 6) मेळाव्याची नोंदणी फी First Marriage मेळावा साठी रु {websiteMessage.first_marriagemelavafee}/- , विधवा विधुर मेळावा साठी  रु {websiteMessage.vidhvavidhur_melavafee}/- ,दोन्ही मेळावासाठी रु {websiteMessage.both}/- असून उमेदवार आणि सोबत दोन पालकांसाठी प्रवेश दिला जाईल</p>

              <p> अधिकच्या व्यक्तीसाठी रु 300/- भरावे लागतील.</p>  

              <p>7)परिचय पुस्तिका व भोजन पास मेळाव्या दिवशी स. 9.00 पासून मेळावा ठिकाणी उपलब्ध होतील</p>  

              <p> 8) मेळाव्या दिवशी पासधारकांना एक वेळच्या भोजनाची निशुल्क व्यवस्था असेल.</p>  

              <p> 9) रजिस्ट्रेशन कन्फर्मेशन रिसीट शिवाय परिचय पुस्तिका अथवा प्रवेश मिळणार नाही याची नोंद घ्यावी.</p>  

              <p>10) रजिस्ट्रेशन मुदत अंतिम तारीख {websiteMessage.reg_enddate} राहील.</p>  

              <p>11) त्यानंतर येणाऱ्या उमेदवारांची नावे पुरवणी पुस्तिकेत येतील आणि पुरवणी पुस्तिका मेळाव्या नंतर प्रसिद्ध केली जाईल.</p>  

              <p> 12) मेळाव्या संदर्भातील नियम व अटी तसेच स्थळ, काळ व वेळ बदलण्याचा अधिकार आयोजकाकडे राहील त्याबाबत कोणतीही तक्रार ग्राह्य मानली जाणार नाही.</p>

              <p> 13) उमेदवार नोंदणी नंतर 7 दिवसाच्याआत Online Payment करणे अनिवार्य आहे, अथवा Login Account बंद करण्यात येईल. </p>
              <p>14) अकाऊंट क्रियेट करताना उमेदवाराचे नावाने अकाऊंट क्रियेट करावे.</p>
              <p>15)आपल्या रजिस्टर मोबाईल नंबर वर SMS येतील</p>

              <p> 16) नोंदणी फी ऑनलाइन भरते वेळी तपशील मध्ये उमेदवारांचे नाव नमूद करावे </p>

            </Col>
            <Col sm={12} md={5} className='p-4 mb-2 rounded shadow mx-3' style={{backgroundColor:"rgb(233, 224, 227)"}}>
            <h6><b style={{color:"red"}}>IMP Notice: As the ParichayPustika is to be printed in Marathi,it is mandatory to fill the information in Marathi in Marathi column.</b></h6><br/>
              <p>1) Ensure that all your details are correct.</p>

              <p>2) Your photo should be of HD quality and identity size.</p>

              <p>3) The background of the photo should be in soft color.</p>

              <p>4)organizer reserves the right to approve/reject photos</p>

              <p>5) The information (details) filled by you in the register form will be printed in the booklet without any correction by the organizer.</p>

              <p>6) The registration fee for the event is Rs {websiteMessage.first_marriagemelavafee}/- First Marriage Melava ,Rs {websiteMessage.vidhvavidhur_melavafee}/- for Vidhava Vidhoor Melava & Rs {websiteMessage.both}/- for Both Melava and admission will be given to the candidate and two accompanying parents</p>

              <p>Rs.300/- for additional person.</p>

              <p>7) Identity booklet and food pass will be available on the day of the melava from 9.00 am at the melava place.</p>

              <p>8) One time free meal will be provided to the pass holders on the day of the melava.</p>

              <p>9) It should be noted that without the Registration Confirmation Receipt the introduction book or admission will not be given.</p>

              <p>10) Last  date of registration will be {websiteMessage.reg_enddate}.</p>

              <p>11) The names of the subsequent candidates will appear in the supplementary booklet and the supplementary booklet will be published after the melava.</p>

              <p>12) The organizer reserves the right to change the rules and conditions of the event as well as the venue, time and place, no complaint will be entertained.</p>

              <p>13) Online payment is mandatory within 7 days after candidate registration, or the login account will be closed.</p>

              <p>14)Account should be created with the name of ‘Candidate’ only.</p>
              <p>15)SMS alert will be delivered on registered mobile of candidate.</p>
              <p>16)Name of the ‘Candidate’ should be written in narration while making payment </p>
            
              
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "rgb(233, 224, 227)"}}>
          <Button  onClick={handleClose3} style={{backgroundColor: "rgb(250, 99, 18)", border:"1px solid rgb(250, 99, 18)"}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </> 
  )
}

export default Login1
