import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './LoginQr.css'
import {useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';
import beforelogo from '../assets/login-before.png'
import Registartion1 from '../UserComponents/LoginNavComponents/Registration1';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Form, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { QrAdminLogin } from '../AllApi/AdminApi/AdminApi';
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter';
import { WebsiteMessage } from '../AllApi/UserApi/UserApi';



function LoginBarCode() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
  
    const saveFun = async (e) => {
      e.preventDefault()
      
      const logObj = {
        email: email,
        password: password,
      }
  
      try {
        const response = await axios.post(
          QrAdminLogin,
          logObj,
        )
  
        if (response.data === 'NOTFOUND') {
        //   alert('Invalid login details')
        toast.error('Invalid login details', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (response.data !== null) {
          const token = response.data.token
          axios.defaults.headers.common['token'] = `Bearer ${token}`
  
          sessionStorage.setItem('userdata',JSON.stringify(response.data.userdata),
          )
          sessionStorage.setItem('token','Bearer ' + response.data.token)
          navigate('/scanPage')
        } else {
        //   alert('Invalid login details')
        toast.error('Invalid login details', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });
        }
      } catch (error) {
        console.error('Error:', error)
        // alert('Server Error') 
        toast.error('Server Error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        
      }
    }
    
    const back = () => {
      sessionStorage.removeItem("userdata");
      sessionStorage.clear()
      navigate("/");       
    }

  return (<>
    <Container fluid className='p-0 '>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
        </div>
        </Col>    

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={110}/>
            </div> 
       </Col>    

       <Col md={1} sm={12} style={{display:"flex",justifyContent:"center"}}>
       <Nav.Link onClick={back}><button className='py-2 px-4 rounded' style={{backgroundColor:"red",color:"white" ,border:"0px"}}><b>Back</b></button></Nav.Link>
        </Col> 
      </Row>   
    <Row className='wrap pb-5 m-0' style={{height:"100vh"}}>
        <Col md={3}></Col>       
        <Col md={6}> <div className='login-container pt-md-5'>     
         <div className='login-form py-5'>
            <form onSubmit={saveFun}>
                <div className='input'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                       <img  src={beforelogo}/>
                    </div>
                    
                    <h1 className='pt-3'>Entry Pass Login</h1>
                    <input id='input' value={email} type="email" placeholder='Email' onChange={(e)=>setEmail(e.target.value) }  required/>

                    <input id='input1' value={password} type="password" placeholder='Password' onChange={(e)=> setPassword(e.target.value)}  required/>

                    <div className='login-button'>
                    <button type="submit"  className='login-btn' >LOGIN</button>
                    </div>
                </div>

            </form> 
           
          </div>
        </div>
       </Col>
       <Col md={3}></Col> 
    </Row>

      {/* -----------------footer --------------------------- */}
    <AdminMasterFooter/>

    </Container>
    </>
  )    
}

    export default LoginBarCode


