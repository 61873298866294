import React, { useEffect, useState } from 'react'
import './LoginQr.css'
import Logo from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import beforelogo from '../assets/registration-before.png';
import afterlogo from '../assets/login-after.png';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Getattendancedata } from '../AllApi/AdminApi/AdminApi';
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter';
import { WebsiteMessage } from '../AllApi/UserApi/UserApi';

const LoginQr = () => {

  const [data, setdata] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state?.Reg_id;

  const logoutFun = () => {
    navigate("/scanPage");
  }

  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);


  const getdata = (id) => {
    console.log("+++++++++++++++++", id);
    axios.get(`${Getattendancedata}${id}`)
      .then((res) => {
        if (res.data.message === 'ALREDYEXIST') {
          console.log("==============", res.data.data)
          setdata(res.data.data)
        }
      })
      .catch((err) => {
        // alert("Failed to record Education Master")
        console.log(err)
      })
  }
  useEffect(() => {
    getdata(state)
  }, [state])

  return (
    <Container fluid className='p-0 m-0' style={{ boxSizing: "border-box" }}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <img src={Logo} style={{ mixBlendMode: "darken" }} alt="" width={160} height={120} />
          </div>
        </Col>

        <Col md={5} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
          </div>
        </Col>

        <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <img src={Logo2} style={{ mixBlendMode: "darken" }} alt="" width={160} height={110} />
          </div>
        </Col>

        <Col md={1} sm={12} style={{ display: "flex", justifyContent: "center" }}>
          <Nav.Link onClick={logoutFun}><button className='py-2 px-4 rounded' style={{backgroundColor:"red",color:"white" ,border:"0px"}}><b>Back</b></button></Nav.Link>
        </Col>
      </Row>

      <Row className='mb-3 m-0'>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
          <img src={beforelogo} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 className='mt-1 mb-0 pb-1 text-center' style={{ color: "rgb(134, 28, 18)" }}><b>Function Entry Attendance</b></h2>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={afterlogo} />
        </div>
      </Row>

      <Row className='m-0 pt-md-5 p-md-4 pb-md-0'>
        <Col className='EntryDone text-danger' style={{ display: "flex", justifyContent: "center" }}>
          <h3 ><b >Already Entry Done.</b><h1 style={{ color: "rgb(0, 247, 0)", fontSize: "50px" }}><b><i class="fa-solid fa-check"></i></b></h1></h3>
        </Col>
      </Row>
      <Row className='m-0 p-md-5 pt-md-0'>
        <Table striped bordered hover size="sm">
          <thead>
            <tr className='text-center'>
              <th style={{backgroundColor:"#b3b3d6"}}>Reg.Id</th>
              <th style={{backgroundColor:"#b3b3d6"}}>Candidate Name</th>
              <th style={{backgroundColor:"#b3b3d6"}}>Name-Receipt Brought By</th>
              <th style={{backgroundColor:"#b3b3d6"}}>Mobile No.</th>
              <th style={{backgroundColor:"#b3b3d6"}}>Date & Time</th>
              <th style={{backgroundColor:"#b3b3d6"}}>No. Of Person</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td>{data.reg_id}</td>
              <td>{data.Candidate_Name}</td>
              <td>{data.Name_Receipt_Brought_By}</td>
              <td>{data.Mobile_No}</td>
              <td>{data.dateTime}</td>
              <td>{data.No_of_person}</td>
            </tr>
           
          </tbody>
        </Table>
      </Row>
     
      {/* -----------------footer login --------------------------- */}
      <AdminMasterFooter/>

    </Container>





  )
}

export default LoginQr
