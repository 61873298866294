import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import '../AdminComponents/AdminLogin.css'
import { adminLogin } from '../AllApi/AdminApi/AdminApi';
import Nav from 'react-bootstrap/Nav';
import Logo from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';
import footer2 from '../assets/whiteDesign.jpg'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader';
import { toast } from 'react-toastify';

const AdminLogin = () => {
  const navigate = useNavigate()
     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleEmail = (e) => {
    setEmail(e.target.value)
    setIsValid(true)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
    setIsValid(true)
  }

  const loginFun = async (e) => {
    e.preventDefault()
    if (email === '' || password === '') {
      setIsValid(false)
      return
    }

    const logObj = {
      email: email,
      password: password,
    }

    try {
      const response = await axios.post(
        // 'http://192.168.29.112:8091/api/admin_logins/adminlogin',
        adminLogin,
        logObj,
      )

      console.log("=====response=====",response);
      

      if (response.data === 'NOTFOUND') {
        toast.error('Invalid login details !!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else if (response.data !== null) {
        const token = response.data.token
        axios.defaults.headers.common['token'] = `Bearer ${token}`

        sessionStorage.setItem('userdata',JSON.stringify(response.data.admindata),
        )
        sessionStorage.setItem('token','Bearer ' + response.data.token)
        navigate('/admin/dashboard')
      } else {
        alert('Invalid login details')
      }
    } catch (error) {
      console.error('Error:', error)
      alert('Server Error') 
    }
  }

  const logoutFun = () => {
    navigate("/");    
  }

  const currentYear = new Date().getFullYear(); // Get current year
  //--------------------------------------------------------------------------------
  //------------------------------------CSS--------------------------------------------

  const box = {
    margin: '60px 20px 10px 20px',
    textAlign: 'center',
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"100%",
  }
  const input = { padding: '10px', marginBottom: '20px', borderRadius:"50px", border:"1px solid" }
  const loginform = {
    border: 'none',
    padding: '20px',
    boxShadow: '5px 10px 15px 0px',
    backgroundColor: 'white',
    height:"315px",
    borderRadius:"10px",
  }
  const button = {
    margin: '10px 0px',
    padding: '10px',
    color: 'white',
    border:"1px solid",
    backgroundColor:"#1F618D",
    borderRadius:"50px",
  }
  const title = { textAlign: 'center', paddingBottom: '20px' }

  const footerStyle = {
    backgroundColor:"rgb(233, 224, 227)",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#000",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
    display:"flex",
    justifyContent:"center"
  }
  const style={
    height: '80vh',
    display: 'flex',
    margin: '0px',
    justifyContent: 'center',
    backgroundImage: `url(${footer2})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    alignItems:"center",
  }

  return (
    <div>
    <Row className='header m-0 p-0 '>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col>    

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={110}/>
            </div> 
       </Col>    

       <Col md={1} sm={12} style={{display:"flex",justifyContent:"center"}}>
       <Nav.Link onClick={logoutFun}><button className='py-2 px-4 rounded' style={{backgroundColor:"red",color:"white" ,border:"0px"}}><b>Back</b></button></Nav.Link>
        </Col> 
      </Row>  

      <Row style={style} >
        <Col style={box} className='row'>
        <div className='col-12 col-md-4 col-sm-3'> </div>
          <div style={loginform} className='col-12 col-md-4 col-sm-6'>
            <h2 style={title}> <i class="fa-solid fa-user pe-3"></i> ADMIN LOGIN</h2>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <input
                  className='col-12'
                  style={input}
                  type="email"
                  placeholder="Username"
                  value={email}
                  onChange={handleEmail}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <input
                  className='col-12'
                  style={input}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handlePassword}
                />
              </Form.Group>

              {isValid ? null : (
                <div style={{ color: 'red', margin: '10px',marginBottom:"-10px" }}>
                  All fields are compulsory*
                </div>
              )}
              <button style={button} type="submit" onClick={loginFun} className='col-12'> 
                Login
              </button>
              {/* <Nav.Link href="/#/Registration1" style={{fontWeight:"600"}}>Registration</Nav.Link> */}
            </Form>
            
          </div>
          <div className='col-12 col-md-4 col-sm-3'> </div>
        </Col>
      </Row>
      <div style={footerStyle}>
        &copy; {currentYear} Jain Boarding | Managed By : TechnoAarv Solution  &nbsp; &nbsp; &nbsp; <Nav.Link href="/#/Registration1" style={{fontWeight:"600"}}>New Registration</Nav.Link>
      </div>
    </div>
  )
}

export default AdminLogin
