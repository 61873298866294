import React,{useEffect,useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import UserMasterFooter from './UserCommonComponent/UserMasterFooter';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import '../../src/UserComponents/UserComponent.css'
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Logo from '../assets/logo2.png'
import Logo2 from '../assets/kolLogo.png'
import Nav from 'react-bootstrap/Nav';
import { WebsiteMessage } from '../AllApi/UserApi/UserApi';



const Dashboard = () => {

  const navigate=useNavigate()
     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  const [isChecked, setIsChecked] = useState(false);


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNextClick = () => {
    // Here you can add your logic to navigate to the next page
    // if the checkbox is checked.
    if (isChecked) {
      
      navigate('/user/regForm');
      
    } 
    // else {
    //   alert("Please accept the terms and conditions.");
    // }
  }

    const [websiteMessage, setWebsiteMessage] = useState([]); 
    const fetchWebsiteMessage = async () => {
      try {
        const response = await fetch(WebsiteMessage);
        const data = await response.json();
        setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
      } catch (error) {
        console.error('Error fetching website message:', error);
      }
    };
  
    useEffect(() => {
      fetchWebsiteMessage();
    }, []);



  // useEffect(() => {
  //   // Add an extra state to the history stack
  //   window.history.pushState(null, null, window.location.href);
  
  //   // Disable the back button for the current state
  //   const handleBackButton = (event) => {
  //     event.preventDefault();
  //     window.history.forward();
  //   };
  
  //   window.addEventListener('popstate', handleBackButton);
  
  //   return () => {
  //     window.removeEventListener('popstate', handleBackButton);
  //   };
  // }, []);

  useEffect(() =>{
    let newStatus=sessionStorage.getItem("status");
    if(newStatus){
      if(newStatus === 3){
        navigate('/userDashboard')
      }
      else if(newStatus == 4){
        navigate('/user/regForm')
      }
      else if (newStatus ==  5){
        navigate('/payment')
      }
      else{
        // alert("hgvcgjasvbc")
      }
    }
    
  },[])

  {/* --------------footer css------------------ */}
  
  const footerStyle = {
    backgroundColor:"#e9e0e3",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#000",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }
  
  return (
    <Container fluid className=' p-0  ' style={{boxSizing:"border-box"}}>
      
           {/* --------------Header------------------ */}
        <div><Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center",color:"rgb(57, 29, 28)" }}>
          <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
            </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> </div>

           {/* --------------Main------------------ */}
        <div>
          <Row className='m-0 py-4 mb-3' style={{display:"flex",justifyContent:"center"}}>
            <Col sm={12} md={5} className='p-4 mb-2  mx-3 rounded shadow' style={{backgroundColor:"rgb(233, 224, 227)"}}>
              <h6><b style={{color:"red"}}>महत्वाची सूचना : परिचय पुस्तिका मराठी मधे छापली जाणार असले मुळे, मराठी रकाण्यात माहिती ही मराठीतच भरणे अनिवार्य आहे.</b></h6>
              <br/>
              <p>1) आपली संपूर्ण माहिती बरोबर असल्याची खातरजमा करा.</p>

              <p> 2) आपला फोटो HD कॉलीटीचा आयडेंटी साईज असावा.</p>  

              <p>3) फोटोचा बॅकग्राउंड सॉफ्ट कलर मध्ये असावा.</p>  

              <p> 4) फोटो मंजूर/नाकारणे याचा अधिकार आयोजकांकडे राहील.</p>

              <p> 5) तुम्ही रजिस्ट्रेशन फॉर्म मध्ये भरलेली माहिती (तपशील) आयोजकांकडून कोणतीही दुरुस्ती न करता आहे तशी पुस्तिकेत छापली जाईल.</p>  

              <p> 6) मेळाव्याची नोंदणी फी First Marriage मेळावा साठी रु {websiteMessage.first_marriagemelavafee}/- , विधवा विधुर मेळावा साठी  रु {websiteMessage.vidhvavidhur_melavafee}/- ,दोन्ही मेळावासाठी रु {websiteMessage.both}/- असून उमेदवार आणि सोबत दोन पालकांसाठी प्रवेश दिला जाईल</p>  

              <p> अधिकच्या व्यक्तीसाठी रु 300/- भरावे लागतील.</p>  

              <p>7) परिचय पुस्तिका व भोजन पास मेळाव्या दिवशी स. 9.00 पासून मेळावा ठिकाणी उपलब्ध होतील.</p>  

              <p> 8) मेळाव्या दिवशी पासधारकांना एक वेळच्या भोजनाची निशुल्क व्यवस्था असेल.</p>  

              <p> 9) रजिस्ट्रेशन कन्फर्मेशन रिसीट शिवाय परिचय पुस्तिका अथवा प्रवेश मिळणार नाही याची नोंद घ्यावी.</p>  

              <p>10) रजिस्ट्रेशन मुदत अंतिम तारीख {websiteMessage.reg_enddate} राहील.</p>  

              <p>11) त्यानंतर येणाऱ्या उमेदवारांची नावे पुरवणी पुस्तिकेत येतील आणि पुरवणी पुस्तिका मेळाव्या नंतर प्रसिद्ध केली जाईल.</p>  

              <p> 12) मेळाव्या संदर्भातील नियम व अटी तसेच स्थळ, काळ व वेळ बदलण्याचा अधिकार आयोजकाकडे राहील त्याबाबत कोणतीही तक्रार ग्राह्य मानली जाणार नाही.</p>

              <p> 13) उमेदवार नोंदणी नंतर 7 दिवसाच्याआत Online Payment करणे अनिवार्य आहे, अथवा Login Account बंद करण्यात येईल. </p>

              <p> 14) विधवा व अपंग यांना नोंदणी फी रु {websiteMessage.vidhvavidhur_melavafee}/- आहे. </p>

            </Col>
            <Col sm={12} md={5} className='p-4 mb-2 rounded shadow mx-3' style={{backgroundColor:"rgb(233, 224, 227)"}}>
            <h6><b style={{color:"red"}}>IMP Notice: As the Parichay Pustika is to be printed in Marathi,it is mandatory to fill the information in Marathi in Marathi column.</b></h6><br/>
              <p>1) Ensure that all your details are correct.</p>

              <p>2) Your photo should be of HD quality and identity size.</p>

              <p>3) The background of the photo should be in soft color.</p>

              <p>4)organizer reserves the right to approve/reject photos</p>

              <p>5) The information (details) filled by you in the register form will be printed in the booklet without any correction by the organizer.</p>

              <p>6) The registration fee for the event is Rs {websiteMessage.first_marriagemelavafee}/- First Marriage Melava ,Rs {websiteMessage.vidhvavidhur_melavafee}/- for Vidhava Vidhoor Melava & Rs {websiteMessage.both}/- for Both Melava and admission will be given to the candidate and two accompanying parents</p>

              <p>Rs.300/- for additional person.</p>

              <p>7) Identity booklet and food pass will be available on the day of the melava from 9.00 am at the melava place.</p>

              <p>8) One time free meal will be provided to the pass holders on the day of the melava.</p>

              <p>9) It should be noted that without the Registration Confirmation Receipt the introduction book or admission will not be given.</p>

              <p>10) Last  date of registration will be {websiteMessage.reg_enddate}.</p>

              <p>11) The names of the subsequent candidates will appear in the supplementary booklet and the supplementary booklet will be published after the melava.</p>

              <p>12) The organizer reserves the right to change the rules and conditions of the event as well as the venue, time and place, no complaint will be entertained.</p>

              <p>13) Online payment is mandatory within 7 days after candidate registration, or the login account will be closed.</p>

              <p>14)Registration fee for the event is Rs {websiteMessage.vidhvavidhur_melavafee}/- for widows and disabled</p>
            
              
            </Col>
          </Row>
          <Row className='mx-md-5 m-0' sm={12}>
          <Col  className='px-md-5  mx-md-3 ' sm={12}>
              <input className='ms-3'
              type="checkbox"
              name="check"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label><p className='ms-sm-3 sm-md-0'><b>I have read the above instructions carefully. (वरील सूचना मी काळजीपूर्वक वाचल्या आहेत.)</b></p></label>
            </Col>
          </Row>
          <Row className='mx-5 mb-5 px-4 m-0'>
              <div className='mb-2 px-4'>
            <button   className='rounded px-3 py-1 mb-4 fs-5 ' 
            // style={{padding:"7px 10px", backgroundColor:"rgb(134, 28, 18)",border:"0px",color:"white",fontSize:"20px"}}
            onClick={handleNextClick} 
            disabled={!isChecked}
            >
              Register
            </button>
            </div>
          </Row>
        </div>
               {/* --------------footer------------------ */}
        <div style={footerStyle}>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
      
    </Container> 
  )
}


export default Dashboard