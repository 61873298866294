import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { FormControl } from 'react-bootstrap';
import beforelogo from '../../assets/registration-before.png';
import afterlogo from '../../assets/login-after.png';
import footerlogo from '../../assets/kolLogo.png';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Logo from '../../assets/logo2.png';
import Logo2 from '../../assets/kolLogo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../UserComponents/UserComponent.css';
import { Link } from 'react-router-dom';
import { BaseIP, WebsiteMessage } from '../../AllApi/UserApi/UserApi';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';


const Registration1 = () => {

     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const navigate = useNavigate()
  const [fName, setFName] = useState('');
  const [mName, setMName] = useState('');
  const [lName, setLName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const [isedit, setIsedit] = useState(false)
  const reg = {
    padding: "10px 20px 20px 20px",
  }

  const input = {
    border: "1px solid",
    margin: "10px 10px 10px 0px",
  }

  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);


  //Registartion Code

  //validation
  const [validated, setValidated] = useState("")
  const [Regshow, setRegshow] = useState(false);
  const handleRegClose = () => setRegshow(false);
  const handleRegShow = () => setRegshow(true);

  //password cheack
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isGenderSelected, setIsGenderSelected] = useState(false);

  function handlePasswordChange(value) {
    setPassword(value);
    setPasswordsMatch(true);
  }

  function handleConfirmPasswordChange(value) {
    setConfirmPassword(value);
    setPasswordsMatch(value === password);
  }

  //Registration Handle Function
  const handleSubmitReg = (event) => {

    event.preventDefault();
    setIsLoading(true)
  

    if (passwordsMatch) {
      if (isGenderSelected && isAgeValid()) {
        const reg_data = {
          fname: fName,
          mname: mName,
          lname: lName,
          mob_no: mobileNumber,
          email: email,
          password: password,
          gender: gender,
          dateofbirth: dateOfBirth
        }


        console.log("DSafsa clcicked", reg_data)
        axios
          .post(`${BaseIP}api/member/memberRegister`, reg_data)
          .then((res) => {
            setIsLoading(false);
            if (res.data === "SAVED") {
              console.log("hhjugbdhbjdb", res.data)
              
              toast.success('Account Created Succesfully! Please login and register', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              navigate('/')

            }
            else if (res.data === "ALREADYEXIST") {
              setIsLoading(false);
              // alert("Email id already registered. Try with another email.... ");
              toast.error('Email id already registered. Try with another email ..!!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          })

          .catch((err) => {
            // alert("Failed To Record Your Registration , try again");
            setIsLoading(false);
            toast.error('Failed To Record Your Registration , try again', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      }
      else {
        setIsLoading(false);
        // alert("Please select gender and ensure you meet the age criteria.");
        toast.error('Please select gender and ensure you meet the age criteria.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      // alert("password  not match");
      setIsLoading(false);
      toast.error('password  not match.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setPasswordsMatch(false);
    }
  };

  const handalegender = (e) => {
    setGender(e.target.value)
    setIsGenderSelected(true)
    setIsedit(true)

  }

  const isAgeValid = () => {
    const selectedDate = new Date(dateOfBirth);
    const today = new Date();
    const ageDiff = today.getFullYear() - selectedDate.getFullYear();

    if (gender === "1" && ageDiff >= 21) {
      return true;
    } else if (gender === "2" && ageDiff >= 18) {
      return true;
    } else {
      return false;
    }
  };



  const handaledob = (e) => {
    setDateOfBirth(e.target.value)
  }
 

  return (
    <div>
      <div>
        <Row className='header m-0 p-0 py-1'>
          <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <img src={Logo2} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
            </div>
          </Col>

          <Col md={6} sm={12} style={{ display: "flex", justifyContent: "center",color:"rgb(57, 29, 28)" }}>
          <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
            </div>
          </Col>

          <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <img src={Logo} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
            </div>
          </Col>

        </Row>

        <Navbar expand="lg" style={{ backgroundColor: "rgb(57, 29, 28)" }}>
          <Container fluid>
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" style={{ backgroundColor: "rgb(233, 224, 227)" }} />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link href="/" className=' mx-2 text-white'>Login</Nav.Link>
                <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>terms & Condition</Nav.Link>
                <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className='regimg' >


        <Container >

          <div className='row' style={{ height: "100%", padding: "55px 0px" }}>
            <div className="col-1"></div>
            <div className="col-10 shadow rounded" style={{ backgroundColor: "rgb(233, 224, 227)" }}>

              <Row className='mb-3'>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
                  <img src={beforelogo} height={20} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className='mt-1 mb-0 pb-1' style={{ color: "rgb(57, 29, 28)" }}><b>Account Creation Form</b></h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>
              </Row>

              <div className='' style={reg}>


                <Form onSubmit={handleSubmitReg}>

                  <div className='row'>
                    <div className="col-md-4">
                      <label htmlFor="firstName">First Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="firstName"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="middleName">Middle Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="middleName"
                        value={mName}
                        onChange={(e) => setMName(e.target.value)}
                        required
                      />
                    </div>


                    <div className="col-md-4">
                      <label htmlFor="lastName">Last Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="lastName"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mobileNumber">Mobile Number:</label>
                      <FormControl
                        pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.'
                        style={input}
                        type="tel"
                        id="mobileNumber"
                        value={mobileNumber}
                        onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 10) {
                          setMobileNumber(value); // Call setMobileNumber with the new value
                        }
                      }}
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="gender">Gender :</label>
                      <Form.Select aria-label="Default select example"
                        style={input}
                        value={gender}
                        onChange={(e) => handalegender(e)}
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="dateOfBirth">Date of birth:</label>
                      <FormControl
                        style={input}
                        type="date"
                        id="dateOfBirth"
                        value={dateOfBirth}
                        onChange={(e) => handaledob(e)}
                        disabled={isedit ? false : true}
                        required
                      />  
                      {!isGenderSelected && (
                        <p style={{ color: "red" }}>Please select gender first.</p>
                      )}
                      {isGenderSelected && !isAgeValid() && (
                        <p style={{ color: "red" }}>
                          Age criteria not met. (Male: 21+ years, Female: 18+ years)
                        </p>
                      )}
                    </div>


                    <div className="col-md-6">
                      <label htmlFor="email">Email ID:</label>
                      <FormControl style={input}
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>


                    <div className="col-md-6">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password">Create Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={password}
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          required
                        />
                       
                      </Form.Group>
                    </div>


                    <div className="col-md-6">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                          required
                        />
                         {!passwordsMatch && <small className="text-danger">Passwords do not match.</small>}
                      </Form.Group>
                    </div>

                  </div>

                  <div style={{ textAlign: "center", paddingTop: "10px", }}>
                  {isLoading ? (
              <Spinner animation="border" variant="primary" /> // Show Spinner while loading
            ) : (
                    <button type="submit" className='rounded' style={{ padding: "7px 19px", backgroundColor: "rgb(57, 29, 28)", border: "0px", color: "white", fontSize: "20px" }} >
                      Register
                    </button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </Container>

          {/* -----------------footer login --------------------------- */}
   <UserMasterFooter/>
      </div>
    </div>
  )
}

export default Registration1

