import React, { useEffect, useState } from 'react';
import '../UserCommonComponent/CommonComponent.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo2 from '../../assets/kolLogo.png';
import { Link } from 'react-router-dom';
import { WebsiteMessage } from '../../AllApi/UserApi/UserApi';




const UserMasterFooter = () => {

  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);

  return (
    <div className=' m-0'>
   <Container fluid className='footer-container p-md-3 m-0 px-md-3' style={{boxSizing:"border-box"}}>
      <Row className='py-3'>
        <Col sm={12} md={5} className='text-white text-center  py-3'>
        <div >
          <img src={Logo2}  alt="" width={100} height={100}/>
        </div> 
        <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
            </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(233, 224, 227)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white" }} >
                   <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            {/* <Link to="/registration1"
                  style={{textDecoration:"none", color:"white"}} >
                  <i style={{paddingRight:"10px",color: "rgb(250, 99, 18)"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(233, 224, 227)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start'>
           
           <a href="mailto:djbkkop@gmail.com" style={{textDecoration: "none",color:"white" }}>
             <i style={{ paddingRight: "10px",color: "rgb(250, 99, 18)" }} className="fa-solid fa-envelope"></i>   
             Email : <span>djbkkop@gmail.com</span>
             </a>
             <br/>
             <a href="tel:2312644339" style={{ textDecoration: "none" ,color:"white"}}>
             <i style={{ paddingRight: "10px",color: "rgb(250, 99, 18)" }} className="fa-solid fa-phone"></i> 
             Contact: 0231 2644339
           </a><br/>
           </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div className='text-center' style={{display:"flex", justifyContent:"center", color:"white", paddingTop:"20px", borderTop:"1px solid gray",paddingBottom:"11px"}}>
            &copy; {websiteMessage.melava_year} Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default UserMasterFooter