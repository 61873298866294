import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import Logo from '../../assets/logo2.png'
import Logo2 from '../../assets/kolLogo.png'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { WebsiteMessage } from '../../AllApi/UserApi/UserApi';


function PrivacyPolacy() {

     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const navigate=useNavigate()
   // {----------------logout-------------------}
  //  const logoutFun = () => {
  //   sessionStorage.removeItem("userdata");
  //   navigate("/");    
  // }
  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); // Assuming response is an array and we're interested in the first item
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);


  return (
 <div>
    <div>
    <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center",color:"rgb(57, 29, 28)" }}>
          <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
            </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

        {/* <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>       */}
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "rgb(57, 29, 28)"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(57, 29, 28)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* <Nav.Link href="/" className=' mx-2 text-white'>Login</Nav.Link>
            <Nav.Link href="/#/registration1" className=' mx-2 text-white'>Registration</Nav.Link> */}
            <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>terms & Condition</Nav.Link>
            <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </div>
    <div className='p-md-4 p-3 mx-md-5'>
      <Nav.Link href="/privacyPolicy" style={{color: "rgb(134, 28, 18)"}}><h3><b>Privacy Policy</b></h3></Nav.Link>
      <p>
      We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.
      </p>
      <h5>What this Privacy Policy Covers?</h5>
      <p>
      This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
      <p>
      This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.
      </p>
      <h5>Information Collection and Use</h5>
      <p>
      We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.
      </p>
      <p>
      When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
      </p>
      <p>
      The e-mail or contact number address You submit to receive information from bduedu.in will never be disclosed to any third party. All e-mail that You receive will be from the domain names bduedu.in.
      </p>
      <p>
      Bharathidasan University has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that Bharathidasan University has developed for this Website are administrative, physical and technical barriers that together form a protective firewall around the information stored at this site.
      </p>
      <p>
      We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.
      </p>
      <p>
      We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.
      </p>
      <h5>Information Sharing and Disclosure</h5>
      <p>We will not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
      <p>
      We have your consent to share the information. <br/>
      We respond to subpoenas, court orders or legal process,;<br/>
      or We find that your actions on our web sites violate the Terms of Service
      </p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
      The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
      </p>
      <h5>Questions or Suggestions</h5>
      <p>If you have questions or suggestions send an email to us:</p>
      <p><a >support@jainboardingsangli.com</a></p>
      </div>
      <div>
      <UserMasterFooter/>
      </div>
    </div>
   )
} 

export default PrivacyPolacy

