import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { currentYearData } from '../AllApi/AdminApi/AdminApi';

const AdminMasterFooter = () => {

  const footerStyle = {
    backgroundColor:"#462f46",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
  const [melavaYear, setMelavaYear] = useState('');

  const fetchMelavaYear = async () => {
    try {
      const response = await axios.get(currentYearData);       
      setMelavaYear(response.data[0].melava_year); 
    } catch (error) {
      console.error('Error fetching melavayear:', error);
    }
  };

  useEffect(() => {
    fetchMelavaYear();
  }, [])

  return (
    <>
      <div style={footerStyle} className='mt-5'>
        &copy; {melavaYear} Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default AdminMasterFooter