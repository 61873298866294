import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import '../UserComponents/UserComponent.css';
// import { useNavigate } from 'react-router-dom';
// import { green } from '@mui/material/colors';
// import afterlogo from '../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import { FormControl } from 'react-bootstrap';
import { RegistrationSelect } from '../AllApi/UserApi/UserApi';
// import { RegistrationSelect } from '../../AllApi/UserApi/UserApi';
import axios from 'axios';
// import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
// import { toast } from 'react-toastify';


const ViewProfile = () => {

     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const member_id = userdata.id


  const [Userdata, setUserdata] = useState()




  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
          console.log("--------------", res.data)
        }
      })
      .catch((err) => {
        // alert("Failed to record Education Master")
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])
  
  return (
    <Container fluid className='m-0 p-0'>
    <div className='m-0 p-0'>
        <UserMasterHeader />
    </div>
    {
      Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

        return (
          <>
            {/* ------------------------Basic Information--------------- */}

    <div className='main-form px-md-5 pb-md-5 pt-md-2  pt-sm-4 pb-sm-4 pb-5' style={{ marginTop: "30px" }}>

        <div className=' rounded shadow p-3 pb-4  ' style={{ backgroundColor: "white" }} >
        <Row style={{ paddingTop: "" }}>
            <Col >
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", borderBottom: "1px solid gray", color: "rgb(134, 28, 18)" }}>
                <div ><h5><b>Basic Information (उमेदवार माहिती)</b></h5>
                </div>
            </div>
            </Col>
        </Row>
        <Row >
            <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label><b>Gender (लिंग):</b></Form.Label></Col>
                <Col sm={6} md={6}>{value.gender === 1 ? 'Male' : 'Female'}</Col>
            </Col>
            <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label><b>Marital Status (वैवाहिक स्थिती) :</b></Form.Label></Col>
                <Col sm={6} md={6}>{value.marital_status }</Col>
            </Col>
            <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label><b>Disability (दिव्यांग):</b></Form.Label></Col>
                <Col sm={6} md={6}>{value.disability }</Col>
            </Col>
        </Row>
        <Row >
            <Col sm={12} md={12} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={4}><Form.Label ><b>Candidate Name English (उमेदवाराचे नाव इंग्रजी) :</b></Form.Label></Col> 
                <Col sm={6} md={8}>{value.en_fname}{' '}{value.en_mname}{' '}{value.en_lname}</Col>
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={12} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={4}><Form.Label ><b>Name In Marathi (उमेदवाराचे नाव मराठी) :</b></Form.Label></Col> 
                <Col sm={6} md={8}>{value.mr_fname}{' '}{value.mr_mname}{' '}{value.mr_lname}</Col>
            </Col>
        </Row>
        <Row >
            <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label ><b>Date Of Birth (जन्म तारीख) :</b></Form.Label></Col> 
                <Col sm={6} md={6}>{value.dob }</Col>
            </Col>
            <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label ><b>Height (उंची):</b></Form.Label></Col> 
                <Col sm={6} md={6}>{value.height }</Col>
            </Col>
        </Row>
        <Row>    
            <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label ><b>Complextion(रंग) :</b></Form.Label></Col> 
                <Col sm={6} md={6}>{value.complextion }</Col>
            </Col>
            <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                <Col sm={6} md={6}><Form.Label ><b>Sub Caste(पोटजात) :</b></Form.Label></Col> 
                <Col sm={6} md={6}>{value.subcastename }</Col>
            </Col>
        </Row>
        </div>
        </div>        

            {/* ---------------------Education----------------------- */}

         <div className="px-md-5 pb-sm-4 pb-4">
            <div className=' rounded shadow p-3 pb-4  ' style={{ backgroundColor: "white" }} >
              {/* <Form onSubmit={handleSave2}> */}
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", borderBottom: "1px solid gray", color: "rgb(134, 28, 18)" }}>
                    <h5 ><b>Education & Occupation Information (शिक्षण आणि व्यवसाय):</b></h5>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label ><b>Educational Category(शैक्षणिक श्रेणी) :</b></Form.Label></Col> 
                    <Col sm={6} md={6}>{value.education_name }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Education (शिक्षण):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.subeducation_name }</Col>
                </Col>
              </Row>

              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label ><b>Other Qualification(इतर पात्रता) :</b></Form.Label></Col> 
                    <Col sm={6} md={6}>{value.other_education }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Occupation Type(व्यवसायाचा प्रकार):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.occupation }</Col>
                </Col>
              </Row>

              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label ><b>Occupation & Address(व्यवसाय आणि पत्ता) :</b></Form.Label></Col> 
                    <Col sm={6} md={6}>{value.occupation_detail }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Annual Income(वार्षिक उत्पन्न):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.amount }</Col>
                </Col>
              </Row>
            </div>
          </div>

          {/* ------------------------------Contact Information----------------------- */}

          <div className="px-md-5 pb-sm-4 pb-5 pt-4">
            <div className=' rounded shadow p-3 pb-4 ' style={{ backgroundColor: "white" }} >
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", borderBottom: "1px solid gray", color: "rgb(134, 28, 18)" }}><h5><b>Contact Information (संपर्क माहिती)</b></h5>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Address (English)पत्ता (इंग्रजी) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.en_address }</Col>
                </Col> 
                  <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Village/City(English) गाव/शहर (इंग्रजी):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.en_village }</Col>
                </Col> 
              </Row>
              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Address(Marathi) पत्ता (मराठी) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.mr_address }</Col>
                </Col> 
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Village/City गाव/शहर(मराठी) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.mr_village }</Col>
                </Col>
              </Row>
              <Row >
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>State(राज्य) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.stateenname }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>District(जिल्हा) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.districtenname }</Col>
                </Col>
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Taluka (तालुका) ::</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.talukaenname }</Col>
                </Col>
             </Row>
              <Row >
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Email Id(ई– मेल):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.emailid }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Mobile No 1 (मोबाईल नं. 1) :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.mobileno }</Col>
                </Col>
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Mobile No 2 (मोबाईल नं. 2):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.mobileno2 }</Col>
                </Col>
             </Row>
            </div>
          </div>

        {/* --------------------------Family Information----------------------- */}
          
        <div className="px-md-5 pt-md-4 pb-md-4 pb-sm-4 pb-4">
            <div className=' rounded shadow p-3 pb-4' style={{ backgroundColor: "white" }} >
              {/* <Form onSubmit={handleSave4}> */}
              <Row style={{ paddingTop: "10px" }}>
                <Col>
                  <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px", borderBottom: "1px solid gray", color: "rgb(134, 28, 18)" }}><h5><b>Family Information (कौटुंबिक माहिती)</b></h5>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col sm={12} md={12} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={4}><Form.Label ><b>Father's Name English (वडिलांचे नाव इंग्रजी) :</b></Form.Label></Col> 
                    <Col sm={6} md={8}>{value.en_fatherfname}{''}{value.en_fathermname}{''}{value.en_fatherlname}{''}</Col>
                </Col>
              </Row>
              <Row >
                <Col sm={12} md={12} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={4}><Form.Label ><b>Father's Name In Marathi (वडिलांचे नाव मराठी) :</b></Form.Label></Col> 
                    <Col sm={6} md={8}>{value.mr_fatherfname}{''}{value.mr_fathermname}{''}{value.mr_fatherlname}</Col>
                 </Col>
              </Row>
              <Row >
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Farm In Acar /शेत जमीन  एकर :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.farm_ac }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Farm In Guntha /शेत जमीन गुंठे :</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.farm_g }</Col>
                </Col>
                <Col sm={12} md={3} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Other Property (इतर मालमत्ता):</b></Form.Label></Col>
                    <Col sm={6} md={6}>{value.other_property }</Col>
                </Col>
             </Row>
              <Row >
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Brother / भाऊ :</b></Form.Label></Col>
                    <Col sm={6} md={6}><b>विवाहित-</b>{value.b_married }{""} &nbsp; <b>अविवाहित-</b>{value.b_unmarried }</Col>
                </Col>
                <Col sm={12} md={6} style={{display:"flex"}} className='pt-3'>
                    <Col sm={6} md={6}><Form.Label><b>Sister / बहीण :</b></Form.Label></Col>
                    <Col sm={6} md={6}><b>विवाहित-</b>{value.s_married}{''} &nbsp; <b>अविवाहित-</b>{value.s_unmarried}</Col>
                </Col>
              </Row>
              
            </div>
          </div>
          </>
              )
            })}
    </Container>
  )
}

export default ViewProfile